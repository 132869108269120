<template>
  <div>
  <div style="border-top: 1px solid #d3d3d32b">     
    <span class="hidden">
      {{ isCustomerUpdate }}
      {{ imageUrl }}
      {{ 'height:' + screenHeight }}
    </span>
    <div class='flex fixed w-full z-50' >
      <div class="flex toggle_section colorBox_text_gray">
        <button class="uppercase text-xs" @click="screenType('Livedata')"
          :class="selected_screen === 'Livedata' ? 'toggle_btns_active' : 'toggle_btns_Inactive'">
          Live
        </button>
        <button  class="uppercase text-xs"
          @click="screenType('stick_diagram')"
          :class="selected_screen === 'stick_diagram' ? 'toggle_btns_active' : 'toggle_btns_Inactive'">
          stick diagram
        </button>
        <button class="uppercase text-xs" @click="screenType('torque_and_drag')"
          :class="selected_screen === 'torque_and_drag' ? 'toggle_btns_active' : 'toggle_btns_Inactive'">
          Torque & Drag
        </button>
      </div>
      <div class="flex toggle_section items-center w-1/5">
        <!-- <div class="image-wrapper mx-2">
          <img :src="imageUrl" style="width: 100%; height: 100%; object-fit: fill; border-radius: 50%;" class="image"
            @error="imgErrorHandler" />
        </div> -->
        <div class="w-full flex m-2 justify-between">
          <span class="colorBox_text_gray text-xs font-semibold flex items-center">
            {{ this.$store.state.data.customer ? this.$store.state.data.customer : 'Please select Customer' }}
          </span>
          <!-- <CustomSelect :options="this.wellNamesOrg" :default="this.dWell" @input="selectWell" class="custom_dash_select text_xs"
            v-show="step === 1" /> -->
          <CustomSelect :options="this.wellBoreNamesOrg" :default="this.dWellbore" @input="selectWellbore"
            class="text_xs" />
          <CustomSelect :options="this.logNames" :default="this.dLog" @input="selectLog" class="w-50"
            />
        </div>
      </div>
    </div>

    <!-- {{ rect.width - 2 }} -->
    <div>
      <div class="flex">
        <div class="w-4/5 fixed top-23 bottom-0 " :style="{ height: screenHeight }" ref="section"
          v-show="selected_screen === 'Livedata'">
          <!-- {{rect.name}} -->

          <!-- <DrillerDIS :id="widgetId" :height="500" :width="500" :top="rect.top" :left="rect.left" :displayId="displayId"
            :plotConfigProps="rect.plotConfig" :isDisProps="rect.isDis" :wellId="selectedWell" :div_id="'dart22'"
            :sectionHeight="sectionHeight" :sectionWidth="sectionWidth" :canvasSection="canvasSection"
            @plot_config="plotConfigHandler" @well_liveTime="wellCurrenttime" /> -->
            <DrillerDIS :id="widgetId" :height="500" :width="500" :top="rect.top" :left="rect.left" :displayId="displayId"
            :plotConfigProps="rect.plotConfig" :isDisProps="rect.isDis" :div_id="'dart22'"
            :sectionWidth="sectionWidth" :canvasSection="canvasSection" :isDrillingDis="true"
            @plot_config="plotConfigHandler" @well_liveTime="wellCurrenttime" :opneMnemonicsModel="opneMnemonicsModel" @getlatestValue="getLatestValuesfromDIS" @unitConv_details ="getUnitConv"/>
        </div>
        <div class="noUi-target_dup w-4/5 fixed top-24 bottom-0 mt-2" :style="{ height: screenHeight-100 + 'px' }" ref="section"
          v-show="selected_screen === 'stick_diagram'">
          <StickDiagram 
          :height="screenHeight-250"
          :selectedWell="selectedWell"
          :selectedWellbore="selectedWellbore"
         
          :selectedLog="selectedLog"
          :key="refreshStick"
          :max="max"
          :dmea="latestDepth"
          :dbtm="latestBitdepth"
          :rop ='rop'
        />
        </div>
        <div class="noUi-target_dup w-4/5 fixed top-24 bottom-0 mt-2" :style="{ height: sectionTDHeight + 'px' }"
          ref="distrenddiv_section" v-show="selected_screen === 'torque_and_drag'">
          <BroomStickPlot :widgetWidth="sectionTDWidth" :widgetHeight="sectionTDHeight" />
        </div>
        <div class="w-5/5 right-section">
          <div style="background-color: var(--nav1BgClrsame);">
            <div class="grid-container">
              <div class="item1 hidden" style="background:var(--navBar2Bgsame)">
                <span class="flex">
                  <div class="w-1/4 mx-auto">
                    <div class="image-wrapper">
                      <img :src="imageUrl" style="width: 100%; height: 100%; object-fit: fill; border-radius: 50%;"
                        class="image" @error="imgErrorHandler">
                    </div>
                    <!-- <img :src="this.$store.state.data.customer ? 'https://apollodarticons.s3.ap-south-1.amazonaws.com/' + this.$store.state.data.customer.split('_')[0].toLowerCase() : '../../assets/img/lithology_icons/user.png'" style="width: 60px;" class="flex justify-between mx-auto"> -->
                  </div>
                  <div class="w-3/4 text-left text-xs ">
                    <div class="flex items-center">
                      <p class="text-white">
                        {{ this.$store.state.data.customer ? this.$store.state.data.customer : 'Please select Customer'
                        }}
                      </p>
                    </div>
                    <div class="block justify-around mt-2">
                      <CustomSelect :options="this.wellNamesOrg" :default="this.dWell" @input="selectWell"
                        class="text_xs" v-if="showLogSelect == false" />
                      <CustomSelect :options="this.wellBoreNamesOrg" :default="this.dWellbore" @input="selectWellbore"
                        class="text_xs hidden" v-if="showWellboreSelect == true" />
                      <CustomSelect :options="this.logNames" :default="this.dLog" @input="selectLog" class="w-50"
                        v-show="showLogSelect == true && showWellboreSelect == true" />
                    </div>
                    <!-- <button @click="setDisplay">Get</button> -->
                  </div>

                </span>

              </div>
              <!-- {{ rect.plotConfig }} -->
                <!-- {{getLatestValuesfromDIS}} -->
              <!-- Mnemonic_data showing based on the trend it will be shown in only mobile view -->
              <div class="item2 colorBox_text_gray" v-for="(mnadata, inx) of Mnemonic_data" :key="inx"
                :style="{ background: 'var(--navBar2Bgsame)', borderLeft: `3px solid red`, borderLeftColor: mnadata.randomColor }">
                <div class="textColor text-sm text-center  mx-auto" :style="{ fontSize: mnadata.width * 0.4 + '%', }">
                  <p :style="{ color: mnadata.randomColor, fontSize: mnadata.width * 0.8 + '%' }">
                    {{ mnadata.full_name === "ACTIVITYCODE" ? "RIG STATE" : mnadata.full_name !== "" ? mnadata.full_name : mnadata.fullName }}
                  </p>
                  <p :style="{ color: mnadata.randomColor, fontSize: mnadata.width * 0.8 + '%' }">
                    {{ getmappedMneminicsData(mnadata.name) }}
                  </p>
                  <div class="flex justify-around mt-1 colorBox_text_gray">
                    <p>
                      {{ mnadata.scale ? mnadata.scale[0] : null }}
                    </p>
                    <p>
                      {{ mnadata.unit }}
                    </p>
                    <p>
                      {{ mnadata.scale ? mnadata.scale[1] : null }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="item2 colorBox_text_gray" :style="{ background: 'var(--navBar2Bgsame)', borderLeft: `3px solid red`}">
                <button class="textColor text-sm text-center mx-auto m-auto flex justify-center items-center" @click="opneMnemonicsModel('Mnemonics')">
                  <p>
                    <i class="fas fa-plus-circle"></i> <br>
                    ADD
                  </p>
                </button>
              </div>
              <!-- Mnemonic_data showing based on the trend it will be shown in only mobile view -->
              <div class="item2 colorBox_text_gray" :class="timeLeft > 0 ? 'danger_blink' : 'danger_without_blink'"
                v-for="(mnadata, inx) of Alert_Mnemonic_data" :key="inx"
                :style="{ background: 'red', borderLeft: `3px solid red`, borderLeftColor: mnadata.randomColor }"
                v-show="mnadata.fullName !== 'time' || mnadata.full_name !== 'time'">
                <div class="textColor text-sm text-center  mx-auto" :style="{ fontSize: mnadata.width * 0.4 + '%', }">
                  <!-- {{ mnadata }} -->
                  <p :style="{ color: mnadata.randomColor, fontSize: mnadata.width * 0.8 + '%' }">
                    {{ mnadata.full_name !== "" ? mnadata.full_name : mnadata.fullName }}
                  </p>
                  <p :style="{ color: mnadata.randomColor, fontSize: mnadata.width * 0.8 + '%' }">
                    {{ getmappedMneminicsData(mnadata.name) }}
                  </p>
                  <div class="flex justify-around mt-1 colorBox_text_gray">
                    <p>
                      {{ mnadata.scale ? mnadata.scale[0] : null }}
                    </p>
                    <p>
                      {{ mnadata.unit }}
                    </p>
                    <p>
                      {{ mnadata.scale ? mnadata.scale[1] : null }}
                    </p>

                  </div>
                </div>
              </div>
              <!-- <div class="item2 text-white"
              :style="{ background: 'var(--navBar2Bgsame)', borderLeft: `3px solid red` }">
              <div class="block">
                <div class="flex w-full text-white font-light mb-2">
                  <div class="flex w-1/2 justify-end">
                    <p>Alerts</p>
                  </div>
                  <div class="flex w-1/2 justify-end">
                    <button>
                      <i class="fas fa-angle-double-right font-light"></i>
                    </button>
                  </div>
                </div>
                <div>
                  <h5 class="danger">
                    High
                  </h5>
                  <div class="">
                    <button class="danger_border px-4  py-1 m-1 danger font-semibold bg-white">
                      <span class="mr-1 danger"><i class="fas fa-exclamation-triangle"></i></span> Erratic MSE <span
                        class="ml-2 text-indigo-500"><i class="fas fa-volume-up"></i></span>
                    </button>
                    <button class="danger_border px-4  py-1 m-1 danger font-semibold bg-white">
                      <span class="mr-1 danger"><i class="fas fa-exclamation-triangle"></i></span> Erratic MSE <span
                        class="ml-2 text-indigo-500"><i class="fas fa-volume-up"></i></span>
                    </button>
                    <button class="danger_border px-4  py-1 m-1 danger font-semibold bg-white">
                      <span class="mr-1 danger"><i class="fas fa-exclamation-triangle"></i></span> Erratic MSE <span
                        class="ml-2 text-indigo-500"><i class="fas fa-volume-up"></i></span>
                    </button>
                  </div>

                </div>

                <div>
                  <h5 class="moderate">
                    Medium
                  </h5>
                  <div class="flex">
                    <button class="moderate_border px-4  py-1 m-1 moderate font-semibold bg-white">
                      <span class="mr-1 moderate"><i class="fas fa-bell"></i></span> Erratic MSE <span
                        class="ml-2 text-red-500"><i class="fas fa-volume-mute"></i></span>
                    </button>
                    <button class="moderate_border px-4  py-1 m-1 moderate font-semibold bg-white">
                      <span class="mr-1 moderate"><i class="fas fa-bell"></i></span> Erratic MSE <span
                        class="ml-2 text-red-500"><i class="fas fa-volume-mute"></i></span>
                    </button>
                    <button class="moderate_border px-4  py-1 m-1 moderate font-semibold bg-white">
                      <span class="mr-1 moderate"><i class="fas fa-bell"></i></span> Erratic MSE <span
                        class="ml-2 text-red-500"><i class="fas fa-volume-mute"></i></span>
                    </button>
                  </div>
                </div>

                <div>
                  <h5 class="text-orange-500">
                    Low
                  </h5>
                  <div class="flex">
                    <button class="low_border px-4  py-1 m-1 low font-semibold bg-white">
                      <span class="mr-1 low"><i class="fas fa-info-circle"></i></span> Erratic MSE <span
                        class="ml-2 text-indigo-500"><i class="fas fa-volume-up"></i></span>
                    </button>
                    <button class="low_border px-4  py-1 m-1 low font-semibold bg-white">
                      <span class="mr-1 low"><i class="fas fa-info-circle"></i></span> Erratic MSE <span
                        class="ml-2 text-indigo-500"><i class="fas fa-volume-up"></i></span>
                    </button>
                    <button class="low_border px-4  py-1 m-1 low font-semibold bg-white">
                      <span class="mr-1 low"><i class="fas fa-info-circle"></i></span> Erratic MSE <span
                        class="ml-2 text-indigo-500"><i class="fas fa-volume-up"></i></span>
                    </button>
                  </div>
                </div>
              </div>
            </div> -->

            </div>
            <div class="w-full flex">
              <div class="w-full mx-1 px-3 py-3 mt-1" style="background:var(--navBar2Bgsame)">
                <div class="block">
                  <div class="flex w-full colorBox_text_gray font-light mb-2">
                    <div class="flex w-1/2 justify-end">
                      <p>Alerts</p>
                    </div>
                    <div>
                      <audio ref="audioPlayer" :src="audioSrc" loop></audio>
                    </div>
                    <div class="flex w-1/2 justify-end">
                      <button @click="openSettings" title="Settings" class="mx-2">
                        <i class="fas fa-cog"></i>
                      </button>
                      <button @click="showAlertshistory()" title="Alerts History">
                        <i class="fas fa-angle-double-right font-light"></i>
                      </button>
                    </div>
                  </div>
                  <div v-if="highAlerts.length > 0">
                    <h5 class="danger">
                      High
                    </h5>
                    <div class="flex flex-wrap">
                      <!-- {{alertfound}} -->
                      <div v-if="highAlerts.length === 0" class="mx-auto">
                        <h3 class="colorBox_text_gray  mx-auto">No alert found</h3>
                        <!-- {{ recievedTime }} -->
                      </div>

                      <button
                        class="danger_border px-4  py-1 m-1 danger font-semibold bg-white  flex justify-between mt-2 mb-1"
                        v-for="(alert, index) in highAlerts" :key="index" @click="selectSingleAlertName(alert)" v-else>
                        <!-- <audio ref="audio" controls class="hidden">
    <source src="../../assets/sounds/warning_1.mp3" type="audio/mpeg">
    Your browser does not support the audio element.
  </audio> -->
                        <!-- {{ isPlaying }} -->

                        <span class="mr-1 danger"><i class="fas fa-exclamation-triangle"></i></span>
                        {{ alert }}
                        <button class="ml-2 text-indigo-500" @click="playSound" v-if="!isPlaying">
                          <i class="fas fa-volume-up"></i>

                        </button>
                        <button class="ml-2 text-red-500" @click="pauseSound" v-else>
                          <i class="fas fa-volume-mute"></i>
                        </button>
                      </button>
                    </div>
                  </div>

                  <div>
                    <h5 class="moderate" v-if="mediumAlerts.length > 0">
                      Medium
                    </h5>
                    <div class="flex">
                      <button class="moderate_border px-4  py-1 m-1 moderate font-semibold bg-white"
                        v-for="(alert, index) in mediumAlerts" :key="index" @click="selectSingleAlertName(alert)">
                        <span class="mr-1 moderate"><i class="fas fa-bell"></i></span> {{ alert }} <button
                          class="ml-2 text-indigo-500" @click="playSound" v-if="!isPlaying">
                          <i class="fas fa-volume-up"></i>

                        </button>
                        <button class="ml-2 text-red-500" @click="pauseSound" v-else>
                          <i class="fas fa-volume-mute"></i>
                        </button>
                      </button>
                    </div>
                  </div>
                  <div v-if="otherAlerts.length > 0">
                    <h5 class="text-orange-500">
                      Low
                    </h5>
                    <div class="flex">
                      <button class="low_border px-4  py-1 m-1 low font-semibold bg-white"
                        v-for="(alert, index) in otherAlerts" :key="index" @click="selectSingleAlertName(alert)">
                        <span class="mr-1 low"><i class="fas fa-info-circle"></i></span> {{ alert }} <button
                          class="ml-2 text-indigo-500" @click="playSound" v-if="!isPlaying">
                          <i class="fas fa-volume-up"></i>

                        </button>
                        <button class="ml-2 text-red-500" @click="pauseSound" v-else>
                          <i class="fas fa-volume-mute"></i>
                        </button>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <!-- <div class="flex pt-5" style="flex-wrap:wrap; background:#0f172a; ">
            <div class="flex text-white navMiddleList textColor text-center mx-2" :style="{background:'var(--navBar2Bgsame)', width:260+'px', height:59+'px', padding:3 +'px',}">
                      <div class="textColor text-sm text-center  mx-auto flex">
                        <div class="w-1/4">
                          <img :src="'https://apollodarticons.s3.ap-south-1.amazonaws.com/' + this.$store.state.data.customer.split('_')[0].toLowerCase()" class="m-1 w-full" />
                        </div>
                        <div class="w-3/4">
                          <div>
                            <p>
                              {{this.$store.state.data.customer}}
                          </p>
                          <p>
                            Customer Name
                          </p>
                        </div>
                        </div>
                        
                      </div>
                  </div>
              <div v-for="mnadata of Mnemonic_data" style="height:59px">
                  <div class="flex text-white navMiddleList textColor text-center" :class="mnadata.class"  :style="{background:mnadata.cardBackground ? mnadata.cardBackground : 'var(--navBar2Bgsame)', width:130+'px', padding:3 +'px', borderLeft:`3px solid red`, borderLeftColor: mnadata.randomColor}">
                      <div class="textColor text-sm text-center  mx-auto" :style="{fontSize: mnadata.width*0.4+'%', }">
                        <p :style="{color:mnadata.randomColor, fontSize: mnadata.width*0.8+'%'}">
                          {{mnadata.fullName}} 
                        </p> 
                        <div class="flex justify-around">
                          <p>
                          {{mnadata.unit}}
                        </p>
                        <p>
                          {{mnadata.unit}}
                        </p>
                        </div>
                      </div>
                  </div>
              </div>
              <div class="w-full flex">
                <div class="flex w-1/2 mx-1 px-3 py-3" style="background:var(--navBar2Bgsame)">
                  <div class="w-full flex">
                      <div class="w-1/2 mx-1 px-3 py-3">
                          <BroomStickPlot class="mt-3">
                              </BroomStickPlot>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mx-1 px-3 py-3" style="background:var(--navBar2Bgsame)">
                      <div class="block">
                        <div class="flex w-full text-white font-light mb-2">
                          <div class="flex w-1/2 justify-end">
                            <p>Alerts</p>
                          </div>
                          <div class="flex w-1/2 justify-end">
                            <button>
                              <i class="fas fa-angle-double-right font-light"></i>
                            </button>
                          </div>
                        </div>
                          <div>
                              <h5 class="danger">
                                  Heigh
                              </h5>
                              <div class="">
                                  <button class="danger_border px-4  py-1 m-1 danger font-semibold bg-white" >
                                    <span class="mr-1 danger"><i class="fas fa-exclamation-triangle"></i></span> Erratic MSE <span class="ml-2 text-indigo-500"><i class="fas fa-volume-up"></i></span>
                                  </button>
                                <button class="danger_border px-4  py-1 m-1 danger font-semibold bg-white" >
                                <span class="mr-1 danger"><i class="fas fa-exclamation-triangle"></i></span> Erratic MSE <span class="ml-2 text-indigo-500"><i class="fas fa-volume-up"></i></span>
                                  </button>
                                  <button class="danger_border px-4  py-1 m-1 danger font-semibold bg-white" >
                                    <span class="mr-1 danger"><i class="fas fa-exclamation-triangle"></i></span> Erratic MSE <span class="ml-2 text-indigo-500"><i class="fas fa-volume-up"></i></span>
                                  </button>
                              </div>
                              
                          </div>
                          
                          <div>
                              <h5 class="moderate">
                                  Medium
                              </h5>
                              <div class="flex">
                                <button class="moderate_border px-4  py-1 m-1 moderate font-semibold bg-white" >
                                    <span class="mr-1 moderate"><i class="fas fa-bell"></i></span> Erratic MSE <span class="ml-2 text-red-500"><i class="fas fa-volume-mute"></i></span>
                                  </button>
                                <button class="moderate_border px-4  py-1 m-1 moderate font-semibold bg-white" >
                                <span class="mr-1 moderate"><i class="fas fa-bell"></i></span> Erratic MSE <span class="ml-2 text-red-500"><i class="fas fa-volume-mute"></i></span>
                                  </button>
                                  <button class="moderate_border px-4  py-1 m-1 moderate font-semibold bg-white" >
                                    <span class="mr-1 moderate"><i class="fas fa-bell"></i></span> Erratic MSE <span class="ml-2 text-red-500"><i class="fas fa-volume-mute"></i></span>
                                  </button>
                              </div>
                          </div>
                          
                          <div>
                              <h5 class="text-orange-500">
                                  Low
                              </h5>
                              <div class="flex">
                                <button class="low_border px-4  py-1 m-1 low font-semibold bg-white" >
                                    <span class="mr-1 low"><i class="fas fa-info-circle"></i></span> Erratic MSE <span class="ml-2 text-indigo-500"><i class="fas fa-volume-up"></i></span>
                                  </button>
                                <button class="low_border px-4  py-1 m-1 low font-semibold bg-white" >
                                <span class="mr-1 low"><i class="fas fa-info-circle"></i></span> Erratic MSE <span class="ml-2 text-indigo-500"><i class="fas fa-volume-up"></i></span>
                                  </button>
                                  <button class="low_border px-4  py-1 m-1 low font-semibold bg-white" >
                                    <span class="mr-1 low"><i class="fas fa-info-circle"></i></span> Erratic MSE <span class="ml-2 text-indigo-500"><i class="fas fa-volume-up"></i></span>
                                  </button>
                              </div>
                          </div>
                      </div>
                </div>

              </div>
          <p>
          </p>
          </div> -->

          </div>
        </div>
      </div>
      <!-- <div class="sendMail_modal appHeader" v-if="showalertPop || openSetting" v-click-outside="handleClickOutside"> -->
      <div class="sendMail_modal appHeader" v-if="showalertPop || openSetting" v-click-outside="handleClickOutside">
        <div class="Child_Of_sendMail_modal alertHistPop" :class="showalertPop ? 'w-2/5' : 'w-1/5'" style="height: 50%;" :style="{right: '28px', bottom: '98px'}">
          <div class="p-4 py-2 flex justify-between items-center border-b">
            <div class="fontWeight-600 text-xs uppercase flex items-center w-full">
              <div class="mx-auto">{{ showalertPop ? 'Alert History' : 'settings' }}</div>
            </div>
            <button v-if="showalertPop" @click="showAlertshistory" class="text-sm font-normal uppercase text-red_"
              style="color: #cf634b">
              <i class="fas fa-times"></i>
            </button>
            <button v-else @click="openSettings" class="text-sm font-normal uppercase text-red_" style="color: #cf634b">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="w-full px-5 py-3 overflow-auto" style="gap: 30px; height: calc(100% - 100)">
            <div v-if="showalertPop">
              <p class="block"> filter: <br>
              <div class="flex">
                <span>
                  From
                  <Datepicker v-model="showMin" class="w-full" :minDate="new Date(showMin).toLocaleString('en-US', {
        timeZone: this.time_zone,
      })
        " :maxDate="new Date(showMax).toLocaleString('en-US', {
        timeZone: this.time_zone,
      })
        " />
                </span>
                <span>
                  To
                  <Datepicker v-model="showMax" class="w-full"
                    :minDate="new Date(showMin).toLocaleString('en-US', { timeZone: this.time_zone, })" :maxDate="new Date(showMax)" @update:modelValue="getUpdatedAlerts(showMin, showMax)" />
                </span>
                <!-- <span>
                  Search
                  <button @click="getUpdatedAlerts(showMin, showMax)">
                    Search
                  </button>
                </span> -->
              </div>

              </p>
              <div class="flex justify-center">
                <p v-if="alertHostoryResp.length <= 0 && loading == true" class="w-1/6">
                  <img src="../../assets/img/ZKZg.gif" alt="" class="w-1/6">
                  <!-- <Loader /> -->
                </p>
                <table v-else class="uppercase">
                  <thead>
                    <tr>
                      <th>ALERT NAME</th>
                      <th>ALERT Time</th>
                      <th class="text-center">VIEW</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(alerts, index) of alertHostoryResp" :key="index">
                      <td>{{ alerts.alert }}</td>
                      <td>{{ new Date(alerts.time).toLocaleString('en-US', { timeZone: this.time_zone, }) }}
                        
                      </td>
                      <td class="flex justify-center onlyEmail">
                        <a @click="viewAlertOnTrend(alerts.alert)" class="curser-pointer">view</a>
                      </td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
            <div v-if="openSetting">
              <ul>
                <li>
                  <button type="button" class="collapsible text-xs" @click="toggleSection(0)">Map alerts</button>
                </li>
                <li class="flex justify-between">
                  <button type="button" class="collapsible text-xs flex justify-between" @click="mapAlert()"><span>Alert Time
                      <small>(sec.)</small></span>
                    <span>
                      <input type="number" class="emailSubmit_Form" min="1" max="10000" v-model="alertTime"
                        @change=startCountdown><span>

                      </span>
                    </span>
                  </button>
                </li>
                <li class="flex justify-between">
                  <button type="button" class="collapsible text-xs flex justify-between" @click="mapAlert()"><span>Prev Alert Count</span>
                    <span>
                      <input type="number" class="emailSubmit_Form" min="1" max="10" v-model="prevAlertCount" @change="prevAlertsget(prevAlertCount)"><span>

                      </span>
                    </span>
                  </button>
                </li>
                <li class="flex justify-between">
                  <span class="collapsible text-xs flex justify-between" for="alertMute">Mute
                    <button class="ml-2 text-indigo-500" @click="playSound" v-if="!isPlaying">
                      <i class="fas fa-volume-up"></i>

                    </button>
                    <button class="ml-2 text-red-500" @click="pauseSound" v-else>
                      <i class="fas fa-volume-mute"></i>
                    </button>
                  </span>
                </li>
                <li>
                  <button type="button" class="collapsible text-xs flex justify-between" @click="toggleSection(1)">
                    <span>
                      SELECT TONE ({{ selectedTonesrc.name }})
                    </span>
                    <span v-if="openSection >= 1">
                      <i class="fas fa-angle-up"></i>
                    </span>
                    <span v-else>
                      <i class="fas fa-angle-down"></i>
                    </span>
                  </button>
                  <div class="content block" v-if="openSection === 1">
                    <ul>
                      <li class="drop_items" v-for="(tones, index) of audioSources" :key="index"
                        @click="selectedTone(tones)">
                        <span class="flex justify-between px-2 text-xs">
                          <p>
                            {{ tones.name }}
                          </p>
                          <p v-if="selectedTonesrc.name === tones.name">
                            <i class="fas fa-check text-emerald-500"></i>
                          </p>
                        </span>
                      </li>
                    </ul>

                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


      <!-- Alert Mapping POPUP start -->
      <div class="sendMail_modal appHeader" v-if="openSection === 0">
        <div class="Child_Of_sendMail_modal alertHistPop w-3/4" style="height: 50%;">
          <div class="p-4 py-2 flex justify-between items-center border-b">
            <div class="fontWeight-600 text-xs uppercase flex items-center w-full">
              <div class="mx-auto">Alert Mapping</div>
            </div>
            <button @click="toggleSection(null)" class="text-sm font-normal uppercase text-red_" style="color: #cf634b">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="w-full px-5 py-3 overflow-auto flex" style="gap: 30px; height: calc(100% - 100)">
            <div class="w-1/5 block" style="border-right:1px solid gray">
              <ul>
                <p class="text-center p-2">
                  Alert Category
                </p>
                <hr />
                <li>
                  <button class="w-full text-left my-4" @click="alertType('ALERTHIGH')"
                    :class="selectedAlertType === 'ALERTHIGH' ? 'danger_text' : ''">
                    High Alerts
                  </button>
                </li>
                <hr />
                <li>
                  <button class="w-full text-left my-4" @click="alertType('ALERTMEDIUM')"
                    :class="selectedAlertType === 'ALERTMEDIUM' ? 'warning_text' : ''">
                    Medium Alerts
                  </button>
                </li>
                <hr />
                <li>
                  <button class="w-full text-left my-4" @click="alertType('ALERTLOW')"
                    :class="selectedAlertType === 'ALERTLOW' ? 'lowWarning_text' : ''">
                    Low Alerts
                  </button>
                </li>
              </ul>
            </div>
            <div class="w-10/12">

              <p v-if="!selectedAlertType">
                No Alerts Found. Please Select Alert Category.
              </p>
              <div v-else>
                <button class=" my-2" v-for="(alert, index) in alertBasedMnemonic" :key="index"
                  @click="selectedAlertName(alert.alertName)"
                  :class="[getButtonClass(alert.alertName), isSelected(alert.alertName) ? TempMapping.preference[selectedAlertType] : 'button_inActive']">
                  {{ alert.alertName }}
                </button>
              </div>
              <button type="submit" class="mt-4 toggleLabel px-2 rounded uppercase text-white"
                v-if="selectedAlertType && TempMapping.preference[selectedAlertType]" @click="confirmAlertMapping()">
                Confirm
              </button>
              <!-- <pre>{{ JSON.stringify(TempMapping, null, 2) }}</pre> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Alert Mapping POPUP end -->


      <!-- mnemonics model List start-->
       <div class="sendMail_modal appHeader" v-if="$store.state.uis.leftSideBarOpen == true">
        <div class="Child_Of_sendMail_modal alertHistPop w-3/4" style="height: 50%;">
          <div class="p-4 py-2 flex justify-between items-center border-b">
            <div class="fontWeight-600 text-xs uppercase flex items-center w-full">
              <div class="mx-auto">Mnemonic List</div>
            </div>
            <button @click="closeMnemonicsModel" class="text-sm font-normal uppercase text-red_" style="color: #cf634b">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="w-full py-2 px-5" style="gap: 30px; height: calc(100% - 100)">
            <p v-if="findingMnemonicselected">
              Loading...
            </p>
            <button class="px-2 my-2 mnBg" v-for="(mnemonic, index) of tags" :key="index" @click="mnemonic.matched == true ? '' : pushtoTrend(mnemonic.pnrg_mnemonic)" :class="mnemonic.matched == true ? 'matchedTag' : ''" v-else>
              {{mnemonic.display_name}}
            </button>
          </div>
        </div>
      </div>
       <!-- mnemonics model List end-->
  </div>
  

  <DashboardPopup v-if="!this.$store.state.data.customer" />
  
  
  </div>
  
</template>
<script>
import { ref, defineAsyncComponent } from "vue";
import tempDashMnData from '../../js/tempDash.json'
// import BroomStickPlot from "../Widgets/DrillerBroomstick/BroomStickPlot.vue";
import customer_default_logo from '../../assets/img/user.png'
// import CustomSelect from "../Select/SelectComponent.vue";
// import DrillerDIS from "../Widgets/DrillerDIS/DrillingTrend.vue"
// import DrillerDIS from "../Widgets/DIS/DisTrend.vue"
import api from "../../api/services";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
// import Loader from "../../layouts/Loader.vue";
import Axios from "axios";
import constant from "../../api/constant";
// import StickDiagram from "../Widgets/Diagram/StickDiagram.vue"
import helperServices from "../../helper-services.js";
// import DashboardPopup from "../Popups/DashboardPopup.vue";
export default {
  components: {
    DashboardPopup: defineAsyncComponent(()=> import("../Popups/DashboardPopup.vue")),
    BroomStickPlot: defineAsyncComponent(()=> import("../Widgets/DrillerBroomstick/BroomStickPlot.vue")),
    CustomSelect: defineAsyncComponent(()=> import("../Select/SelectComponent.vue")),
    DrillerDIS: defineAsyncComponent(()=> import("../Widgets/DIS/DisTrend.vue")),
    Datepicker,
    Loader: defineAsyncComponent(()=> import("../Popups/DashboardPopup.vue")),
    StickDiagram: defineAsyncComponent(()=> import("../Widgets/Diagram/StickDiagram.vue"))
    // customer_default_logo
  },

  data() {
    return {
      latestBitdepth:0,
      latestDepth:0,
      rop : 0 ,
      refreshStick : 234,
      all_alert_mnemonics: [],
      refreshTrend: 4876543,
      displayId: "dashboard_display_id",
      widgetId: "dashboard_trend_id",
      rect: {
        "width": 256,
        "height": 500, //this.screenHeight ,
        "top": 0,
        "left": 5,
        "draggable": false,
        "resizable": true,
        "minw": 10,
        "minh": 10,
        "axis": "both",
        "parentLim": true,
        "snapToGrid": false,
        "zIndex": 9999,
        "active": false,
        "class": "box-shaddow",
        "name": "drilling_trend",
        "autoFit": true,
        "isWidget": true,
        "offsetY": 5.46875,
        "offsetX": 13.5625,
        "titleDisplay": true,
        "valueDisplay": true,
        "plotConfig": {
          "height": this.screenHeight - 200,
          "width": "260",
          "UWI": "00/01-01-095-19W4/0",
          "curveName": "CHALKTALK",
          "tracks": [
            [
              {
                "name": "hkl",
                "fullName": "hook load",
                "color": "#41e100",
                "trackNbr": 0,
                "curveNbr": 0,
                "trackId": "t3hm7u_dashboard",
                "min": 51.41224,
                "max": 52.6431,
                "scale": [
                  0,
                  300
                ],
                "scaleType": "auto",
                "stroke": 2,
                "decimals": "1",
                "description": "hook load",
                "unit": "tonnes",
                "conversion_factor": "",
                "full_name": "HOOK LOAD",
                "unit_category": "Mass"
              },
              {
                "name": "wob",
                "fullName": "wob",
                "color": "#ec6409",
                "trackNbr": 0,
                "curveNbr": 1,
                "trackId": "tck8u_dashboard",
                "min": 181.5774,
                "max": 182.842,
                "scale": [
                  0,
                  50
                ],
                "scaleType": "auto",
                "stroke": 2,
                "decimals": "1",
                "description": "wob",
                "unit": "tonnes",
                "conversion_factor": "",
                "full_name": "WOB",
                "unit_category": "Mass"
              },
              {
                "name": "bpos",
                "fullName": "block position",
                "color": "#c800d2",
                "trackNbr": 0,
                "curveNbr": 2,
                "trackId": "tewo8c_dashboard",
                "min": 84.90261,
                "max": 84.90261,
                "scale": [
                  -10,
                  200
                ],
                "scaleType": "auto",
                "stroke": 2,
                "decimals": "2",
                "description": "block position",
                "unit": "m",
                "conversion_factor": "",
                "full_name": "BLOCK POS",
                "unit_category": "Length"
              }
            ],
            [
              {
                "name": "diff",
                "fullName": "diff press",
                "color": "#e1415f",
                "trackNbr": 1,
                "curveNbr": 1,
                "trackId": "thiv8i_dashboard",
                "min": -2055.678,
                "max": -2051.494,
                "scale": [
                  0,
                  4500
                ],
                "scaleType": "auto",
                "stroke": 2,
                "description": "diff press",
                "unit": "kpa",
                "conversion_factor": "",
                "full_name": "DIFF PRESS",
                "unit_category": "Pressure"
              },
              {
                "name": "rop",
                "fullName": "ROP",
                "color": "#50b4c8",
                "trackNbr": 1,
                "curveNbr": 2,
                "trackId": "tp5mvv_dashboard",
                "scale": [
                  0,
                  500
                ],
                "scaleType": "auto",
                "stroke": 2,
                "description": "ROP",
                "unit": "ft/h",
                "conversion_factor": "",
                "full_name": "ROP",
                "unit_category": "Speed"
              },
              {
                "name": "gamma_ray",
                "fullName": "GAMMA RAY",
                "color": "#11d425",
                "trackNbr": 1,
                "curveNbr": 2,
                "trackId": "tp0q2q_dashboard",
                "scale": [
                  0,
                  300
                ],
                "scaleType": "auto",
                "stroke": 2,
                "description": "GAMMA RAY",
                "unit": "",
                "conversion_factor": "",
                "full_name": ""
              }
            ],
            [
              {
                "name": "mfi",
                "fullName": "flow_in",
                "color": "#06eac4",
                "trackNbr": 2,
                "curveNbr": 0,
                "trackId": "t82l67_dashboard",
                "min": 0,
                "max": 0,
                "scale": [
                  0,
                  1000
                ],
                "scaleType": "auto",
                "stroke": 2,
                "description": "flow_in",
                "unit": "m3/min",
                "conversion_factor": "",
                "full_name": "MUD FLOW IN",
                "unit_category": "Flow rate"
              },
              {
                "name": "flow_out",
                "fullName": "FLOW OUT",
                "color": "#CE86DB",
                "trackNbr": 2,
                "curveNbr": 1,
                "trackId": "tl6jnb_dashboard",
                "scale": [
                  0,
                  100
                ],
                "scaleType": "auto",
                "stroke": 2,
                "description": "FLOW OUT",
                "unit": "%",
                "conversion_factor": "",
                "full_name": "FLOW OUT",
                "unit_category": "Percentage"
              },
              {
                "name": "activitycode",
                "fullName": "activitycode",
                "color": "#d6470a",
                "trackNbr": 2,
                "curveNbr": 2,
                "trackId": "tq1kx7_dashboard",
                "scale": [
                  0,
                  5000
                ],
                "scaleType": "auto",
                "stroke": 2,
                "description": "activitycode",
                "unit": "kpa",
                "conversion_factor": "",
                "full_name": "activitycode",
                "unit_category": "Pressure"
              }
            ],
          ],
          "curveType": "dmea",
          "depthName": "dmea"
        },
        "label": [
          null
        ],
        "isDis": true,
        "isReplay": false
      },
      Mnemonic_data: [],
      Alert_Mnemonic_data: [],
      colors: ["#OOFA9A", "#00FF7F", "#FF0000", "#FF7F00", "#FF4500", "#FF6EB4", "#8A2BE2", "#9400D3", "#FF00FF", "#BF00FF", "#FF007F", "#FF1493", "#FCFF00", "#FFFF00", "#FFD700", "#FF8C00", "#7FFF00", "#00FFC2", "#00FF01", "#01F8FF", "#00CFD2", "#00A5FF", "#0000FF"],
      colorIndex: 0,
      // randomColor: this.getRandomColor()
      wellSelectionObj: {},
      wellBoreSelectionObj: {},
      wellNames: [],
      wellNamesOrg: [],
      dWell: "Well",
      dWellbore: "Wellbore",
      dLog: "Log",
      wellBoreNames: [],
      wellBoreNamesOrg: [],
      logNames: [],
      wellBores: [],
      selectedWell: '',
      selectedWellbore: '',
      selectedLog: '',
      wells: this.$store.state.data.wells ? this.$store.state.data.wells : [],
      alertsfound: [],
      previousAlerts: [],
      showalertPop: false,
      alertHostoryResp: [],
      mnemonicLive_data: {},
      recievedTime: {},
      FromCond: '', // Specify appropriate min value
      EndCond: '', // Specify appropriate max value
      timeUpdated: false,
      historyAlertsMnemonics: [],
      showWellboreSelect: true,
      showLogSelect: false,
      currentAlert: {},
      alertBasedMnemonic: [],
      MnemonicListTemp: [],
      isMuted: false,
      muteStatus: [],
      highAlertCategory: [],
      midAlertCategory: [],
      lowAlertCategory: [],
      highAlertList: false,
      highAlerts: [],
      mediumAlerts: [],
      otherAlerts: [],
      audioSources: [
        { name: 'warning_1', tone: require('../../assets/sounds/warning_1.mp3') },
        { name: 'warning_2', tone: require('../../assets/sounds/warning_2.mp3') },
        { name: 'warning_3', tone: require('../../assets/sounds/warning_3.mp3') },
        { name: 'warning_4', tone: require('../../assets/sounds/warning_4.mp3') },
        { name: 'warning_5', tone: require('../../assets/sounds/warning_5.mp3') },
      ],
      selectedTonesrc: '',
      audioSrc: require('../../assets/sounds/warning_1.mp3'), // Path to your MP3 file
      isPlaying: false,
      isSpeaking: false,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      sectionWidth: 0,
      sectionHeight: 0,
      canvasSection: this.sectionWidth,
      mouseX: 0,
      mouseY: 0,
      openSetting: false,
      openSection: null,
      switch3: false,
      alertTime: 10,
      prevAlertCount:2,
      timeLeft: 10,
      countdownInterval: null,
      options: [
        { value: 'option1', text: 'Option 1' },
        { value: 'option2', text: 'Option 2' },
        { value: 'option3', text: 'Option 3' },
      ],
      selectedOptions: [],
      selectedAlertType: '',
      selectedNames: [],
      TempMapping: {
        well_id: "",
        wellbore_name: "",
        user_id: null,
        preference: {
          ALERTHIGH: [],
          ALERTMEDIUM: [],
          ALERTLOW: []
        }
      },
      mappedAlertData: [],
      loading: false,
      selected_screen: 'Livedata',
      sectionTDWidth: 0,
      sectionTDHeight: window.innerHeight,
      step: 1,
      dbPreviousAlerts: [],
      intervalId: null,
      tags:[],
      findingMnemonicselected:false,
      currentUnitConv:{}
    }
  },
  props: {
    onToggleSideBar: { type: Function }
  },
  setup() {

  },
  beforeMount() {

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenSize);
    window.removeEventListener('resize', this.calculateSectionDimensions);
    window.removeEventListener('resize', this.calculateTD_Dimensions);
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    window.removeEventListener('resize', this.updateSectionWidth);
    this.stopInterval();
  },
  methods: {
    deleteCurrentDisplay(){
      let wells = this.$store.state.data.wells;
      if(
        (
          Object.keys(wells).length && this.$store.state.disp.displays[this.displayId] && 
          this.$store.state.disp.displays[this.displayId].wellId && 
          !wells.hasOwnProperty(this.$store.state.disp.displays[this.displayId].wellId)
        ) ||
        (
          this.$store.state.disp.displays[this.displayId] && 
          this.$store.state.disp.displays[this.displayId].wellId &&
          !Object.keys(wells).length
        )
      ){
        delete this.$store.state.disp.displays[this.displayId]
      }
    },
    // handleClickOutside() {
    //   // alert()
    //   console.log('__event__', event)
    //   this.showalertPop = !this.showalertPop;
    //   this.openSetting = !this.openSetting;
    // },
    checkSTARTDate() {
      // Implement your date validation logic here
    },
    openDatePicker() {
      this.$refs.datePicker.focus();
      // console.log('DatePicker opens:', this.$refs.datePicker);
    },
    plotConfigHandler(pconfig) {
      // console.log('___pconfig_data___', pconfig)
      // console.log('___pconfig_data___rect', wellCurrenttime)
      this.Mnemonic_data = pconfig.reduce((acc, curr) => acc.concat(curr), []);
      // console.log('___pconfig_data___', this.Mnemonic_data)
      // console.log('___pconfig_data___rect', this.rect.plotConfig)
      // this.rect.plotConfig
      this.shuffleColors();
      this.assignColors();
    },
    getLatestValuesfromDIS(curve) {
      // console.log('___what_is_curve_____actualData___', curve.name);
      // console.log('___what_is_curve_____actualData___Mnemonic_data', this.Mnemonic_data);
      let name = curve.name;
      let value = "0";
      let decimals = curve.decimals || 0;
      if (this.Mnemonic_data[name] && this.Mnemonic_data[name][this.Mnemonic_data[name].length - 1]) {
        let value = this.Mnemonic_data[name][this.Mnemonic_data[name].length - 1];
        if (!isNaN(value)) {
          if (name == "activitycode") {
            return this.wellActcCodes[value]?.name;
          } else return value.toFixed(decimals);
        } else if (name == "alert" && value) {
          return value;
        } else if (name == "washoutindex") {
          return "";
        }
      } else {
        if (
          name == "alert" ||
          name == "washoutindex" ||
          name == "activitycode"
        ) {
          return "";
        } else {
          return value;
        }
      }
    },
    getUnitConv(convValues){
      // console.log('___convValues___', convValues)
      this.currentUnitConv = convValues
    },
    wellCurrenttime(time) {
      let timezone_obj = { timeZone: this.time_zone, hour12: false };
      var endTime = new Date(time.end).toLocaleString(
        "en-US",
        timezone_obj
      );
      var startTime = new Date(time.start).toLocaleString(
        "en-US",
        timezone_obj
      );
      this.recievedTime = {
        from: startTime,
        to: endTime
      }
      // console.log('_________wellCurrenttime________', 'start:' + startTime, 'end:' + endTime)
    },
    getmappedMneminicsData(name) {
      // console.log('___currentUnitConv____', this.currentUnitConv)
     
      // const matchedValue = this.mnemonicLive_data[this.currentUnitConv.name]?.slice(-1)[0];
      // console.log('___currentUnitConv____filter', this.mnemonicLive_data[name])
      // console.log('___mnemonicLive_data___', this.mnemonicLive_data)
      if (this.mnemonicLive_data && this.mnemonicLive_data[name] && this.mnemonicLive_data[name].length > 0) {
        // console.log('_______getmappedMneminicsData!!!!!!!!!', this.mnemonicLive_data)
        // console.log('_______getmappedMneminicsData_____!!!!!!!!!', this.mnemonicLive_data[name][this.mnemonicLive_data[name].length - 1] ? (this.mnemonicLive_data[name][this.mnemonicLive_data[name].length - 1].toFixed(3))*30.48 : 0)
        if(this.currentUnitConv.name === name){
         return this.mnemonicLive_data[name][this.mnemonicLive_data[name].length - 1] ? Math.floor(this.mnemonicLive_data[name][this.mnemonicLive_data[name].length - 1] * this.currentUnitConv.conversion_factor) : 0
          }else {
          return this.mnemonicLive_data[name][this.mnemonicLive_data[name].length - 1] ?  (this.mnemonicLive_data[name][this.mnemonicLive_data[name].length - 1]).toFixed(1) : 0
        }

      } else {
        return 0
      }
      return this.mn
    },
    shuffleColors() {
      for (let i = this.colors.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this.colors[i], this.colors[j]] = [this.colors[j], this.colors[i]];
      }
    },
    assignColors() {
      this.Mnemonic_data.forEach(item => {
        // Check if randomColor property already exists, if not, initialize it
        if (!item.hasOwnProperty('randomColor')) {
          item.randomColor = this.colors[this.colorIndex];
        } else {
          item.randomColor = this.colors[this.colorIndex];
        }
        this.colorIndex = (this.colorIndex + 1) % this.colors.length;
      });
      // console.log('mnemonics with colors', this.Mnemonic_data)
    },
    randomIndex() {
      return Math.floor(Math.random() * this.colors.length);
    },
    imgErrorHandler(event) {
      event.target.src = customer_default_logo;
    },
    selectWell(wellName) {
      // console.log(wellName, "well name");
      this.selectedWellName = wellName;
      if (wellName) {
        this.wellBoreNames = [];
        this.wellBoreNamesOrg = [];
        if (wellName != "Well") {
          wellName = wellName.split(" - ")[0];

          wellName = this.wellSelectionObj[wellName];

          this.selectedWell = wellName.split(" - ")[0];

          this.dWellbore = "Wellbore";
          this.dLog = "Log";

          for (let i in this.wells[wellName]["wellbores"]) {
            this.wellBoreNames.push(
              i +
              (this.wells[wellName].rigname
                ? " - " + this.wells[wellName].rigname
                : "")
            );
            this.wellBoreSelectionObj[
              this.wells[wellName]["wellbores"][i].wellbore_name
            ] = i;
            this.wellBoreNamesOrg.push(
              this.wells[wellName]["wellbores"][i].wellbore_name +
              (this.wells[wellName].rigname
                ? " - " + this.wells[wellName].rigname
                : "")
            );
          }

          if (this.wellBoreNamesOrg && this.wellBoreNamesOrg.length >= 1) {
            this.selectWellbore(this.wellBoreNamesOrg[0]);
            this.dWellbore = this.wellBoreNamesOrg[0];
          }
        }
      }
      this.step = 2
      this.step = 3
      this.showWellboreSelect = true;
      this.showLogSelect = false;
      // this.refreshStick += 11
    },
    selectWellbore(wellboreName) {
      // console.log("called select well bore ", wellboreName);
      if (wellboreName) {
        this.logNames = [];
        if (wellboreName != "Wellbore") {
          this.dLog = "Log";
          // this.dWellbore=wellboreName;
          // wellboreName = wellboreName.split(" - ")[0];
          // wellboreName = this.wellBoreSelectionObj[wellboreName];
          this.selectedWellbore = wellboreName;
          // this.logNames = Object.keys(
          //   this.wells[this.selectedWell]["wellbores"][this.selectedWellbore][
          //   "logs"
          //   ]
          // );
   
          for(let wKey in this.wells){
            // console.log("select-------------======",this.wells,wellboreName,this.wells[wKey].wellbores);
            let wellBorefound= this.wells[wKey].wellbores[wellboreName];
            if(wellBorefound){
              this.selectedWell=wKey;
              this.logNames = Object.keys(wellBorefound.logs)
              // console.log("Logs name::==============",this.logNames);
            }
          }
          if(this.logNames && this.logNames?.length){
            this.$toast.clear();
            this.step = 3; 
          }
          else{
            this.step = 2;
            this.$toast.clear();
            this.$toast.error(`Logs not found`, {
              duration: "2000",
              position: "top",
              pauseOnHover: true,
            });
          }
          if (this.isAlertScreen) {
            try {
              this.selectLog("time");
              // let wellBoreName =
              //   this.wells[this.selectedWell]["wellbores"][
              //   this.selectedWellbore
              //   ]["wellbore_name"];
              this.selectedWellForAlertsScreen =
                this.wells[this.selectedWell]["name"] + "-" + wellBoreName;
            } catch (error) {
              console.log("exicuted for alerts>>>>>>", error);
            }
          }
          if (this.isLwd) {
            try {
              this.selectLog("depth");
              let wellBoreName =
                this.wells[this.selectedWell]["wellbores"][
                this.selectedWellbore
                ]["wellbore_name"];
              this.selectedWellForAlertsScreen =
                this.wells[this.selectedWell]["name"] + "-" + wellBoreName;
            } catch (error) {
              console.error("exicuted for lwd>>>>>>", error);
            }
          }
        }
      }


      this.showLogSelect = false;
      this.refreshStick += 11
    },
    async selectLog(logName) {
      // this.step = 2
      this.showMax = "";
      if (logName !== "Log") {
        this.selectedLog = logName;
        this.openDrop = false;
        // this.step = 2
        this.setDisplay();
        this.showWellboreSelect = true;
        // this.showLogSelect = false;
        this.getAlertsMapped()
        this.getHighCategory()
        this.getMediumCategory()
        this.getLowCategory()
        this.startCountdown()
        this.getPrevAlerts()
        this.startInterval();
        this.getAlertBasedMnemonics()
        // this.showLogSelect = false
        // console.log('selected__step', this.step = 1)
     
        // this.filteredAlerts()
      }
      this.refreshStick += 21
      this.getTags()
      console.log('____selectedDisplay___tagss_selectedDisplay',this.$store.state.disp.selectedDisplay)
      console.log('____selectedDisplay___tagss_displays',this.$store.state.disp.displays)
    },
    subscribeWellDetails(clientId) {
      this.sockets.subscribe(clientId, (sResult) => {
        // console.log('____sResult____', sResult)this.mnemonicLive_data
        // const alertDetails = sResult['dashboard_trend_id'].alert
        // // console.log('____sResult____alertDetails', alertDetails)
        // this.alertsfound = alertDetails
        // console.log('___alert__found_without_null', this.alertfound)
        this.mnemonicLive_data = { ...sResult['dashboard_trend_id'], ...sResult[this.displayId] }
        // console.log("~~~~~~~~~~~~~ this.mnemonicLive_data ~~~~~~~~~~~~~", this.mnemonicLive_data)
        const dmeaList = this.mnemonicLive_data['dmea']
        const dbtmList = this.mnemonicLive_data['dbtm']
        const ropList = this.mnemonicLive_data['rop']
        console.log('___alertss___', this.mnemonicLive_data['alert'])
        // console.log('coming to the dashboard',dmeaList,dbtmList)
        if(dmeaList){
          this.latestDepth = dmeaList[dmeaList.length-1]
        }
       
        if(dbtmList){
          this.latestBitdepth = dbtmList[dbtmList.length - 1]
        }

        if(ropList){
          this.rop = ropList[ropList.length - 1]
        }
        // console.log(dmeaList,dbtmList,'dmea and dbtm lists')

        // setInterval(()=>{
        //   this.latestBitdepth+=1
        //   this.latestDepth +=1

        // },100)
        // console.log(this.latestDepth,this.latestBitdepth,'latest depths')
       
        
        
        
        let details = sResult[this.displayId];
        if (details && this.dLog == "time") {
          const timeDetails = details["time"];
          if (timeDetails && timeDetails.length > 0) {
            const latestTime = timeDetails[timeDetails.length - 1];
            let timezone_obj = { timeZone: this.time_zone, hour12: false };
            if (!this.time_zone) {
              timezone_obj = {};
            }
            this.showMax = new Date(latestTime).toLocaleString(
              "en-US",
              timezone_obj
            );
            console.log('____show_time_max__', this.showMax)
          }
        } else if (details && details["dbtm"]) {
          const dbtmDetails = details["dbtm"];
          
          if (dbtmDetails && dbtmDetails.length > 0) {
            const latestDepth = dbtmDetails[dbtmDetails.length - 1];
            this.showMax = parseFloat(latestDepth).toFixed(2);
          }
        }

        // console.log('___showMaxTime___', this.showMax)

      });
    },
    // async setDisplay(displayId) {
    async setDisplay() {
      this.step = 1
      let remainingDetails = {};
      console.log('____well__list__', this.wells,this.selectedWell)
      if (
        this.wells[this.selectedWell]["wellbores"][this.selectedWellbore][
        "logs"
        ][this.selectedLog]
      )
        remainingDetails =
          this.wells[this.selectedWell]["wellbores"][this.selectedWellbore][
          "logs"
          ][this.selectedLog];
      this.max = remainingDetails.endTime
      let topicName = remainingDetails.topicname; // 'KSQL_DB_W_6_TPSmithHVUnitB2H_1'
      this.time_zone = remainingDetails.time_zone;
      let logTypeId = remainingDetails.logTypeId;
      let trajectoryLogId = null;
      let trajectoryLog =
        this.wells[this.selectedWell]["wellbores"][this.selectedWellbore][
        "logs"
        ]["trajectory"];

      if (trajectoryLog !== undefined && trajectoryLog !== null) {
        trajectoryLogId = trajectoryLog?.id;
      }
      const updated_well_Details = await api.WellServices.getWellDetails({
        well_id: this.selectedWell,
        well_borename: this.selectedWellbore,
        customer: this.$store.state.data.customer,
        log: remainingDetails.type,
      });
      console.log('~~~~~~~~~updated_well_Details~~~~~~~~~~~', updated_well_Details)

      let status = remainingDetails.state;
      let wellIntervalData = "5_sec";
      if (updated_well_Details.status == 200 && updated_well_Details.data) {
        wellIntervalData = updated_well_Details.data.log_interval;
        status =
          updated_well_Details.data.well_status == 4 ||
            updated_well_Details.data.well_status == 104
            ? "UPDATING"
            : updated_well_Details.data.well_status;
      } else {
        status = remainingDetails.state;
      }

      let logType = remainingDetails.type;

      let wellboreState =
        this.wells[this.selectedWell]["wellbores"][this.selectedWellbore].state;
      if (wellboreState == 0) wellboreState = "inactive";
      else wellboreState = "active";

      let min = null;
      let max = null;

      if (logType === "depth") {
        min = remainingDetails.startDepth;
        max = remainingDetails.endDepth;
        this.showMin = parseFloat(min).toFixed(2);
        this.showMax = parseFloat(max).toFixed(2);
      }

      if (logType === "time") {
        min = remainingDetails.startTime;
        max = remainingDetails.endTime;
        let timezone_obj = { timeZone: this.time_zone, hour12: false };
        if (!this.time_zone) {
          timezone_obj = {};
        }
        this.showMin = new Date(remainingDetails.startTime).toLocaleString(
          "en-US",
          timezone_obj
        );

        this.showMax = new Date(remainingDetails.endTime).toLocaleString(
          "en-US",
          timezone_obj
        );

      }

      if (typeof this.showMax == "string" && this.showMax)
        this.showMax = this.showMax.replace("T", " ").replace("Z", "");

      this.$store.dispatch("disp/setDisplay", {
        ...remainingDetails,
        display: this.displayId,
        wellId: this.selectedWell,
        wellboreId: this.selectedWellbore,
        wellBoreName: this.selectedWellbore,
        logId: remainingDetails.id,
        logType: remainingDetails.type,
        wellboreState: wellboreState,
        table: remainingDetails.table,
        min: min,
        max: max,
        status: status,
        trajectoryLogId: trajectoryLogId,
        wellName: this.wells[this.selectedWell]?.name,
        rigName: this.wells[this.selectedWell]?.rigname,
        logTypeId: logTypeId,
        topicName: remainingDetails.topicname,
        swabSurgeTopicName: remainingDetails.swab_surge_topicname,
        time_zone: this.time_zone,
        srcWellId: remainingDetails.srcWellId,
        srcWellBoreId: remainingDetails.srcWellBoreId,
        type: remainingDetails.type,
        id: remainingDetails.id,
        startTime: remainingDetails.startTime,
        endTime: remainingDetails.endTime,
        topicname: remainingDetails.topicname,
        swab_surge_topicname: remainingDetails.swab_surge_topicname,
        unit_conversion: this.wells[this.selectedWell].unit_conversion,
        state: status,
        well_interval: wellIntervalData,
      });

      if (this.ispreviousSuscribed) {
        // await this.takeOffSocketSubNavBar(this.previousSuscribedDetails);
        this.ispreviousSuscribed = false;
      }

      if (status == "UPDATING") {
        setTimeout(async () => {
          this.topicsName = topicName;
          this.$socket.emit("subscribe", {
            logId: `${this.selectedWell}~**~=~**~${this.selectedWellbore}~**~=~**~${logType}`,
            topic: topicName,
            fields: [...this.all_alert_mnemonics, 'alert'],
            id: this.displayId, //displayId ? displayId : this.displayId,
            clientId: this.$clientId,
            logType: logType,
          });
          await this.subscribeWellDetails(this.$clientId);
          this.ispreviousSuscribed = true;
          this.previousSuscribedDetails = {
            logId: `${this.selectedWell}~**~=~**~${this.selectedWellbore}~**~=~**~${logType}`,
            topic: topicName,
            id: this.displayId, //displayId ? displayId : this.displayId,
            clientId: this.$clientId,
          };
        }, 1000);
       
          
      }
    },
    async showAlertshistory() {
      // this.subscribeWellDetails(clientId)
      console.log('___time___getting___', 'min:' + new Date(this.showMin).getTime(), 'max:' + new Date(this.showMax).getTime())
      console.log('___time___getting___rect', this.$store.state.disp.rects)
      this.showalertPop = !this.showalertPop
      if(this.showalertPop){
        this.getUpdatedAlerts(this.showMin, this.showMax)
      }
    },
    openSettings(settings) {
      console.log('__selected_welllllbore', this.selectedWellbore)
      if (this.selectedWellbore === '') {
        this.$toast.info(`@${this.$store.state.data.customer} please select Well details`)
      } else {
        this.openSetting = !this.openSetting
        this.mouseX = settings.clientX;
        this.mouseY = settings.clientY;
        console.log('x:' + this.mouseX, 'y:' + this.mouseY)
        this.TempMapping['user_id'] = this.$store.state.data.userDetails['entity_id']
        this.TempMapping['well_id'] = this.selectedWell
        this.TempMapping['wellbore_name'] = this.selectedWellbore
        this.getAlertsMapped()
      }
      // const mouseX = settings.clientX;
      // const mouseY = settings.clientY;
      // console.log('x:', mouseX, 'y:', mouseY);

    },
    async getUpdatedAlerts(from, to) {
      console.log('____from_to___', from, to)
      this.timeUpdated = true
      this.showMin = from
      this.showMax = to

      var alertObj = {
        well_id: this.selectedWell,
        wellbore_name: this.selectedWellbore,
        from: new Date(this.showMin).getTime(),
        to: new Date(this.showMax).getTime(),
        alertname: 'HELD UP'
      }
      if (this.showalertPop == true && this.timeUpdated == true) {
        this.loading = true
        let response = await api.AlertServices.getAlertHistory(alertObj);
        // this.alertHostoryResp = response.data

        let filteredData = response.data.filter(alert => alert.alert !== null && alert.alert.trim() !== "");

        // Sort the filtered data by time in descending order
        filteredData.sort((a, b) => new Date(b.time) - new Date(a.time));

        // Assign the filtered and sorted data to alertHostoryResp
        this.alertHostoryResp = filteredData;
        console.log('recieved_alertDetails', response.data)
        this.loading = false
      }
      console.log('~~~~~~this.alertHostoryResp~~~~~~', this.alertHostoryResp);

    },
    async viewAlertOnTrend(alertName) {
      console.log('_viewAlertOnTrend_', alertName)
      try {
        Axios.post(constant.DATASERVICES_URL + "alertmnemonics/search", { alertName: alertName }).then((mnemonics) => {
          console.log('___alerts_mnemonics___', mnemonics.data.length)
          if (mnemonics.data.length <= 0) {
            this.$toast.info('Mnemonics not found for this alert please contact Admin')
          } else {
            console.log('__plot_config__before', this.rect.plotConfig.tracks[1])
            this.historyAlertsMnemonics = mnemonics.data
            this.rect.plotConfig.tracks[1] = this.rect.plotConfig.tracks[1].map((track, index) => {
              console.log('__plot_config__after', this.historyAlertsMnemonics[0].mnemonics[index])
              if (this.historyAlertsMnemonics[0].mnemonics[index]) {
                track.name = this.historyAlertsMnemonics[0].mnemonics[index].toLowerCase();
                console.log('__plot_config__before', this.rect.plotConfig.tracks[1])
                // this.refreshTrend+=1;
              }
              return track;
            });
            this.setDisplay();
            // this.Mnemonic_data = this.historyAlertsMnemonics.reduce((acc, curr) => acc.concat(curr), []);
          }
        })
      } catch (error) {
        console.log(error)
      }
      this.setDisplay();
      let alertOnTrend =
        console.log('_viewAlertOnTrend_alertOnTrend', alertOnTrend)
    },
    pushtoTrend(mnemonic) {
    // Find tracks with fewer than three items
    const tracksWithLessThanThreeItems = this.rect['plotConfig'].tracks.map((track, index) => ({
        index: index,
        track: track
    })).filter(item => item.track.length < 3);
    console.log('___selected_mnemonic___tracksWithLessThanThreeItems', tracksWithLessThanThreeItems);
    console.log('___selected_mnemonic___Mnemonic_data', this.Mnemonic_data);
    
    if (tracksWithLessThanThreeItems.length > 0) {
        // Iterate over each track with fewer than 3 items
        tracksWithLessThanThreeItems.forEach(item => {
            const targetTrackIndex = item.index;
            const track = this.rect.plotConfig.tracks[targetTrackIndex];

            // Add new items until the track has 3 items
            while (track.length < 3) {
                track.push({
                    name: mnemonic.toLowerCase(),
                    fullName: '',
                    color: '',
                    trackNbr: targetTrackIndex,
                    curveNbr: track.length,
                    trackId: '',
                    min: 0,
                    max: 0,
                    scale: [0, 0],
                    scaleType: 'auto',
                    stroke: 2,
                    decimals: '1',
                    description: '',
                    unit: '',
                    conversion_factor: '',
                    full_name: '',
                    unit_category: '',
                    randomColor:  '#OOFA9A'
                });
            }

            // console.log('___selected_mnemonic___updatedTrack', this.rect.plotConfig.tracks[targetTrackIndex]);
        });

        // Update the historyAlertsMnemonics property
        this.historyAlertsMnemonics = mnemonic;
        // Refresh the display
        this.setDisplay();
    } else {
        console.log('No tracks with less than three items found.');
            if (this.Mnemonic_data.length >= 0) {
              this.Mnemonic_data.push({
                    name: mnemonic.toLowerCase(),
                    fullName: mnemonic.toLowerCase(),
                    color: '',
                    trackNbr: 4,
                    curveNbr: 1,
                    trackId: '',
                    min: 0,
                    max: 0,
                    scale: [0, 0],
                    scaleType: 'auto',
                    stroke: 2,
                    decimals: '1',
                    description: '',
                    unit: '',
                    conversion_factor: '',
                    full_name: mnemonic.toLowerCase(),
                    unit_category: '',
                    randomColor:  '#OOFA9A'
                });
            }              
            // console.log('___selected_mnemonic___updatedTrack', this.rect.plotConfig.tracks[targetTrackIndex]);
        // });

        // Update the historyAlertsMnemonics property
        this.historyAlertsMnemonics = mnemonic;
        // this.closeMnemonicsModel()
        // Refresh the display
        // this.setDisplay();
    }
    this.findingMatchedMnemonics()
    this.closeMnemonicsModel()
},
    // pushtoTrend(mnemonic){
    //   console.log('___selected_mnemonic___', mnemonic)
    //   console.log('___selected_mnemonic___rect', this.rect['plotConfig'])
    //   const tracksWithLessThanThreeItems = this.rect['plotConfig'].tracks.map((track, index) => ({
    //       index: index,
    //       track: track
    //   })).filter(item => item.track.length < 3);
    //   console.log('___selected_mnemonic___tracksWithLessThanThreeItems', tracksWithLessThanThreeItems);

    //   // console.log('__plot_config__before', this.rect.plotConfig.tracks[1])
    //         this.historyAlertsMnemonics = mnemonic
    //         this.rect.plotConfig.tracks[tracksWithLessThanThreeItems[0].index] = this.rect.plotConfig.tracks[0].map((track, index) => {
    //           console.log('__plot_config__after', this.historyAlertsMnemonics)
    //           if (this.historyAlertsMnemonics) {
    //             track.name = this.historyAlertsMnemonics.toLowerCase();
    //             console.log('__plot_config__before', this.rect.plotConfig.tracks[1])
    //             // this.refreshTrend+=1;
    //           }
    //           return track;
    //         });
    //         this.setDisplay();
    // },
    async getAlertBasedMnemonics() {
      try {
        var mnemonics = await Axios.get(constant.DATASERVICES_URL + 'alertmnemonics/list')
        this.alertBasedMnemonic = mnemonics.data
        this.all_alert_mnemonics = []
        this.alertBasedMnemonic.map(a =>
          this.all_alert_mnemonics = [...this.all_alert_mnemonics, ...a.mnemonics]
        )
        this.all_alert_mnemonics = [...new Set(this.all_alert_mnemonics)]
        this.all_alert_mnemonics = this.all_alert_mnemonics.map(a => a.toLowerCase())

        console.log('___getAlertBasedMnemonic ~~~~~~~~~~~~~~ ', this.alertBasedMnemonic, this.all_alert_mnemonics)
      } catch (error) {
        console.error("error alert based mnemonics : ", error)
      }

    },
    
    selectSingleAlertName(alertName) {
    console.log('__i have clicked automatic___');
    console.log('__alertName__', typeof(alertName));

    let filteredAlertedName = [];
    
    if (typeof(alertName) === 'string') {
        // If alertName is a string, filter based on it
        filteredAlertedName = this.alertBasedMnemonic.filter(alert => alert.alertName === alertName);
    } else if (Array.isArray(alertName)) {
        // If alertName is an array, process each element
        alertName.forEach(name => {
            const filtered = this.alertBasedMnemonic.filter(alert => alert.alertName === name);
            filteredAlertedName = filteredAlertedName.concat(filtered);
        });
    } else if (typeof(alertName) === 'object') {
        // If alertName is an object, handle according to its structure
        // Assuming object has a property 'alerts' which is an array of names
        if (alertName.alerts && Array.isArray(alertName.alerts)) {
            alertName.alerts.forEach(name => {
                const filtered = this.alertBasedMnemonic.filter(alert => alert.alertName === name);
                filteredAlertedName = filteredAlertedName.concat(filtered);
            });
        }
    }

    const newMnemonics = filteredAlertedName.flatMap(alert => {
        return alert.mnemonics.map(mnemonic => ({
            name: mnemonic.toLowerCase(),
            full_name: mnemonic.toLowerCase(),
            fullName: mnemonic.toLowerCase()
        }));
    });

    // Combine the existing mnemonics with the new ones
    const combinedMnemonics = this.Alert_Mnemonic_data.concat(newMnemonics);

    // Create a map to ensure only the latest unique mnemonics are kept
    const mnemonicMap = {};
    combinedMnemonics.forEach(mnemonic => {
        mnemonicMap[mnemonic.name] = mnemonic;
    });

    // Convert the map values back to an array
    this.Alert_Mnemonic_data = Object.values(mnemonicMap);

    console.log('___getAlertBasedMnemonic________all_alert_mnemonics ', this.Alert_Mnemonic_data);
},

    playAudio() {
      this.isMuted = !this.isMuted;
      const audioElement = this.$refs.audio;
      console.log('__audioElement__', audioElement);
      audioElement.muted = this.isMuted;
      if (!this.isMuted) {
        audioElement.play();
      } else {
        audioElement.pause();
      }
    },
    async playSound() {
      try {
        if (!this.isPlaying && !this.isSpeaking) {
          const utterance = new SpeechSynthesisUtterance(this.previousAlerts + 'Alert Recieved');
          this.isSpeaking = true;

          // Create a promise that resolves when the speech ends
          const speakPromise = new Promise((resolve) => {
            utterance.onend = () => {
              this.isSpeaking = false;
              resolve();
            };
          });

          // Speak the text
          speechSynthesis.speak(utterance);

          // Wait for the speech to finish
          await speakPromise;
        }

        // Play or pause the audio based on isPlaying state
        if (!this.isPlaying) {
          await this.$refs.audioPlayer.play();
        } else {
          this.$refs.audioPlayer.pause();
        }
        this.isPlaying = !this.isPlaying;

      } catch (error) {
        console.error('Failed to play audio:', error);
      }
    },

    pauseSound() {
      this.$refs.audioPlayer.pause();
      this.isPlaying = false;
    },
    getHighCategory() {
      console.log('___this.mappedAlertData___', this.mappedAlertData)
      if(this.mappedAlertData){

        if (this.mappedAlertData.preference) {
          this.highAlertCategory = this.mappedAlertData.preference['ALERTHIGH']
          console.log('__alertCategory__high_from_cass', this.mappedAlertData)
  
        } else {
          try {
            Axios.get(constant.DATASERVICES_URL + "miscellaneous/type/values?type=ALERTHIGH").then((highAlertCategory) => {
              console.log("__alertCategory__high_default", highAlertCategory.data)
              this.highAlertCategory = highAlertCategory.data
              // this.mappedAlertData.preference['ALERTHIGH'] = highAlertCategory.data
            })
            // this.getAlertBasedMnemonics()
          } catch (error) {
            console.log(error)
          }
        }
      }
    },
    getMediumCategory() {
      if(this.mappedAlertData){

        if (this.mappedAlertData.preference) {
          this.midAlertCategory = this.mappedAlertData.preference['ALERTMEDIUM']
          console.log('__alertCategory__mid_from_cass_', this.midAlertCategory)
  
        } else {
          try {
            Axios.get(constant.DATASERVICES_URL + "miscellaneous/type/values?type=ALERTMEDIUM").then((midAlertCategory) => {
              console.log("__alertCategory__mid_default", midAlertCategory.data)
              this.midAlertCategory = midAlertCategory.data
            })
            // this.getAlertBasedMnemonics()
          } catch (error) {
            console.log(error)
          }
        }
      }
    },
    getLowCategory() {
      if(this.mappedAlertData){

        if (this.mappedAlertData.preference) {
          this.lowAlertCategory = this.mappedAlertData.preference['ALERTLOW']
          console.log('__alertCategory__low_from_cass', this.lowAlertCategory)
  
        } else {
          try {
            Axios.get(constant.DATASERVICES_URL + "miscellaneous/type/values?type=ALERTLOW").then((lowAlertCategory) => {
              console.log("__alertCategory__low__default", lowAlertCategory.data)
              this.lowAlertCategory = lowAlertCategory.data
            })
          } catch (error) {
            console.log(error)
          }
        }
      }
    },
    updateScreenSize() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    },
    calculateSectionDimensions() {
      const section = this.$refs.section;
      console.log('__section____', section.clientWidth)
      if (section) {
        this.sectionWidth = section.clientWidth;
        this.sectionHeight = section.clientHeight;
      }
    },
    calculateTD_Dimensions() {
      const section = this.$refs.distrenddiv_section;
      console.log('~~~~~section~~~~~~~~', section.clientWidth)
      var distrenddiv_sectionWidth = section.clientWidth - 100
      this.distrenddiv_sectionWidth = distrenddiv_sectionWidth
    },
    toggleCollapsible() {
      this.openSection = this.openSection === 0 ? null : 0; // Toggle collapsible section
    },
    toggleSection(section) {
      this.openSection = this.openSection === section ? null : section; // Toggle selected section
    },
    startCountdown() {
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }
      this.timeLeft = this.alertTime; // Set the countdown time to alertTime
      this.countdownInterval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          clearInterval(this.countdownInterval);
        }
      }, 1000);
    },
    prevAlertsget(enteredCount){
      console.log('entered count is::', enteredCount)
      if(enteredCount <= 0){
        this.$toast.warning('Count should be more than 0')
        this.prevAlertCount = 1
        this.getPrevAlerts()
      }
      else if(enteredCount >10){
        this.$toast.warning('Count should be not more than 10')
        this.prevAlertCount = 10
        this.getPrevAlerts()
      }else{
        this.prevAlertCount = enteredCount
        this.getPrevAlerts()
      }
    },
    resetCountdown() {
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }
      this.timeLeft = this.alertTime; // Reset the countdown time to alertTime
    },
    selectedTone(tone) {
      // Update selectedTonesrc with the tone path     
      this.selectedTonesrc = tone;
      // Update the audio source and reload the audio element
      this.audioSrc = tone.tone;
      this.$nextTick(() => {
        const audioPlayer = this.$refs.audioPlayer;
        audioPlayer.load();
        audioPlayer.play().catch(error => {
          console.error('Failed to play audio:', error);
        });
      });

      // console.log('___selectedTone_was___', tone);
    },
    alertType(alert) {
      this.selectedAlertType = alert;
      console.log('___this.mappedAlertData_________', this.mappedAlertData)
      if(this.mappedAlertData || this.mappedAlertData != undefined){
          this.TempMapping = this.mappedAlertData
          this.isSelected(this.mappedAlertData.preference['ALERTHIGH'])
          this.isSelected(this.mappedAlertData.preference['ALERTLOW'])
          this.isSelected(this.mappedAlertData.preference['ALERTMEDIUM'])
        }else{
          this.isSelected(this.mappedAlertData['ALERTHIGH'])
          this.isSelected(this.mappedAlertData['ALERTLOW'])
          this.isSelected(this.mappedAlertData['ALERTMEDIUM'])
        }
    },
    selectedAlertName(alertName, alertType) {
      if (!this.selectedAlertType) {
        console.error('No alert type selected');
        return;
      }

      // Remove the alert name from all alert types
      Object.keys(this.TempMapping.preference).forEach(type => {
        const index = this.TempMapping.preference[type].indexOf(alertName);
        if (index !== -1) {
          this.TempMapping.preference[type].splice(index, 1);
        }
      });

      // Add the alert name to the selected alert type
      if (!this.TempMapping.preference[this.selectedAlertType]) {
        this.$set(this.TempMapping.preference, this.selectedAlertType, []);
      }
      this.TempMapping.preference[this.selectedAlertType].push(alertName);
      this.selectedNames.push(alertName);

      // Log the updated TempMapping to the console
      console.log('TempMapping', this.TempMapping);
    },
    isSelected(alertName) {
      console.log('___alert___', alertName)
      return this.selectedNames.includes(alertName);
    },
    getButtonClass(alertName) {
      console.log('___alert___~~~~~~getButtonClass~~~~~~~~~', this.TempMapping)
      const alertType = Object.keys(this.TempMapping.preference).find(key => this.TempMapping.preference[key].includes(alertName));
      if (alertType) {
        switch (alertType) {
          case 'ALERTHIGH':
            return 'button_danger';
          case 'ALERTMEDIUM':
            return 'button_medium';
          case 'ALERTLOW':
            return 'button_low';
          default:
            return '';
        }
      } else {
        return '';
      }
    },
    async confirmAlertMapping() {
      var payload_data = {
        ...this.TempMapping,
        user_id: this.$store.state.data.userDetails['entity_id']
      }
      try {
        await api.AlertServices.saveAlertMapping(payload_data).then((savedAlert) => {
          console.log('___savedAlertData___', savedAlert)
          this.toggleSection(null)
          this.startCountdown()
          this.getHighCategory()
          this.getMediumCategory()
          this.getLowCategory()
        })
      } catch (error) {
        console.log(error)
      }
    },
    async getAlertsMapped() {
      var getdata = {
        well_id: this.selectedWell,
        wellbore_name: this.selectedWellbore,
        user_id: this.$store.state.data.userDetails['entity_id']
      }
      try {
        // let receieved = await api.AlertServices.getAlertHistory(alertObj);
        await api.AlertServices.getAlertsMapped(getdata).then(receieved => {
          this.mappedAlertData = receieved.data[0]
          console.log('___receieved___', this.mappedAlertData)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async getPrevAlerts() {
      const getdataPayload = {
        well_id: this.selectedWell,
        wellbore_name: this.selectedWellbore,
        user_id: this.$store.state.data.userDetails['entity_id'],
        limit: this.prevAlertCount
        // time: new Date(this.showMax).getTime()
      };
      if(this.$store.state.data['tabNumber'] === 0){
        try {
          const receieved = await api.AlertServices.getPrevAlerts(getdataPayload);
          this.dbPreviousAlerts = receieved.data;
          // this.alertHostoryResp = receieved.data
          console.log('___received___PrevAlerts', this.dbPreviousAlerts);
        } catch (error) {
          console.error('Error fetching previous alerts:', error);
        }
      }else{
        console.log('Current selected Tab is: ', this.$store.state.data['tabNumber'])
      }
    },
    startInterval() {
      console.log('___opened__tab', this.$store.state.data['tabNumber'])
      this.intervalId = setInterval(this.getPrevAlerts, 10000); // 10000 milliseconds = 10 seconds
    },
    stopInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    categorizeAlerts(alerts) {
      console.log('___categorize_alertss', alerts)
      const highAlertCategoryLower = this.highAlertCategory.map(alert => alert.toLowerCase());
      const midAlertCategoryLower = this.midAlertCategory.map(alert => alert.toLowerCase());

      this.highAlerts = alerts.filter(alert => highAlertCategoryLower.includes(alert.toLowerCase()));
      this.mediumAlerts = alerts.filter(alert => midAlertCategoryLower.includes(alert.toLowerCase()));
      this.otherAlerts = alerts.filter(alert => !highAlertCategoryLower.includes(alert.toLowerCase()) && !midAlertCategoryLower.includes(alert.toLowerCase()));

      console.log('___AlertList___high', this.highAlerts);
      console.log('___AlertList___med', this.mediumAlerts);
      console.log('___AlertList___low', this.otherAlerts);
    },
    screenType(screen) {
      this.selected_screen = screen
      this.updateSectionWidth();
      this.$nextTick(() => {
        this.updateSectionWidth();
        if (screen === 'Livedata' || screen === 'stick_diagram') {
          this.setDisplay();
        }
      });


    },
    updateSectionWidth() {
      if (this.$refs.distrenddiv_section) {
        this.sectionTDWidth = this.$refs.distrenddiv_section.offsetWidth;
        this.sectionTDHeight = window.innerHeight - 150 //this.$refs.distrenddiv_section.offsetHeight;
        console.log('___sectionTDWidth__', this.sectionTDHeight);
      }

    },
    async getTags() {
      try{
      // console.log('__________gettttTAGSSSSS_dashboard', this.tags)
      let display = 'dashboard_display_id'//this.$store.state.disp.selectedDisplay;
      let displayobj = this.$store.state.disp.displays;
      if (displayobj[display]) {
        let displayobj1 = await helperServices.getIndexDetails(
          displayobj[display].wellId
        );
        // console.log('__________gettttTAGSSSSS_dashboard', displayobj1)
        displayobj1 = displayobj1.data["logs"][displayobj[display].logType];

        this.mneTags = displayobj1.tags;
        this.units = displayobj1.units;

        this.logId = displayobj1.logId;
        this.state = displayobj1.wellboreState;
        this.description = displayobj1.description;
        this.displayTags = displayobj1.displayTags;
        const mnemonics = displayobj1.mnemonics;
        const mnk = Object.keys(displayobj1.mnemonics);
        this.tags = [];
        mnk.forEach((m) => {
          if (m != "MISCELLANEOUS") {
            const currentMn = Object.keys(mnemonics[m]);
            currentMn.forEach((n) => {
              this.tags.push(mnemonics[m][n]);
              // this.tags.push(mnemonics[m][n].display_name);
              console.log('__________gettttTAGSSSSS_dashboard', mnemonics[m][n])
            });
          }
        });
        // this.tags = this.displayTags;
        return [];
      } else {
        this.tags = [];
        return [];
      }
      console.log('___this_is_tags__data', this.tags)
      }catch(err){console.error('from catch',err)}
    },
    async closeMnemonicsModel(){
      this.$store.state.uis.leftSideBarOpen = false
      // console.log("___action____", action, await this.$store.state.uis.leftSideBarOpen)
      // await this.onToggleSideBar(action);
     this.$store.state.uis.leftSideBarOpen ? (this.clickedLink = action) : (this.clickedLink = "");
    }, 
    async opneMnemonicsModel(action) {
      console.log('___search_mnList___', this.Mnemonic_data)
      console.log('___search_mnList___action', this.tags)
      this.$store.state.uis.leftSideBarOpen = true;
      
      // There is no need to await this.$store.state.uis.leftSideBarOpen because it is not a promise
      if (this.$store.state.uis.leftSideBarOpen == true) {
        this.clickedLink = action;
      } else {
        this.clickedLink = "";
      }
      this.getTags()
      this.$toast.info('please wait...')
      this.findingMnemonicselected = true
      setTimeout(async () => {
        this.findingMatchedMnemonics()
        this.findingMnemonicselected = false
      }, 2000);
    },
    findingMatchedMnemonics(){
      const updatedTagsData = this.tags.map(tag => {
            const matchingMnemonic = this.Mnemonic_data.find(mnemonic => 
                mnemonic.fullName === tag.display_name || mnemonic['full name'] === tag.display_name || mnemonic['full_name'] === tag.display_name || mnemonic['description'] === tag.display_name || mnemonic['name'] === tag.pnrg_mnemonic
            );

            if (matchingMnemonic) {
                return {
                    ...tag,
                    matched: true
                };
            } else {
                return {
                    ...tag,
                    matched: false
                };
            }
        });
    this.tags = updatedTagsData
        console.log('___search_mnList___updatedTagsData', updatedTagsData);
    }
    // async opneMnemonicsModel(){
    //   this.$store.state.uis.leftSideBarOpen = true
    //   // console.log("___action____", action, await this.$store.state.uis.leftSideBarOpen)
    //   // await this.onToggleSideBar(action);
    //   (await this.$store.state.uis.leftSideBarOpen)
    //     ? (this.clickedLink = action)
    //     : (this.clickedLink = "");
    // }
  },
  computed: {
    isCustomerUpdate() {
      try {
        console.log(" is customer update ", this.selectedWell,this.selectedWellbore)
        if (this.$store.state.data.isCustomerUpdate) {
          this.$store.dispatch("data/setCustomerUpdate", false);
          this.wellNames = Object.keys(this.$store.state.data.wells);
          console.log(" this.wellNames : ", this.wellNames)
          this.wellNamesOrg = [];
          this.wellBoreNames = [];
          this.wellBoreNamesOrg = [];
          // this.logNames = [];
          this.wellBores = [];
          this.showMax = "";
          this.dLog = "Log";
          if (!this.wellNames.length) {
            if (this.$store.state.disp.rects.length) {
              this.$store.state.disp.rects.map((display) => {
                this.$store.dispatch("disp/setDisplay", {
                  display: display.displayId,
                  wellId: "",
                  wellboreId: "",
                  logId: "",
                  logType: "",
                  wellboreState: "",
                  table: "",
                  min: "",
                  max: "",
                  status: "",
                  trajectoryLogId: "",
                  logTypeId: "",
                });
              });
            }
          } else {
            let well_name = -1;
            this.wellNamesOrg = [];
            for (let i in this.$store.state.data.wells) {
              const wells = this.$store.state.data.wells;
              // this.wellNames.push(
              //   i +
              //   (this.$store.state.data.wells[i].rigname
              //     ? " - " + this.$store.state.data.wells[i].rigname
              //     : "")
              // );
              // this.wellSelectionObj[this.$store.state.data.wells[i].name] = i;
              // this.wellNamesOrg.push(
              //   this.$store.state.data.wells[i].name +
              //   (this.$store.state.data.wells[i].rigname
              //     ? " - " + this.$store.state.data.wells[i].rigname
              //     : "")
              // );
              this.wellBoreNamesOrg= [...this.wellBoreNamesOrg,...Object.keys(wells[i].wellbores)];

              let wellBores = this.$store.state.data.wells[i].wellbores;
              if (well_name === -1) {
                for (let eachWelBore of Object.keys(wellBores)) {
                  let state = wellBores[eachWelBore].state;
                  console.log("logs:::::::", state);
                  well_name = state === "UPDATING" ? i : -1;
                }
              }
            }
            if (well_name == -1) {
              well_name = this.wellNames[0] || "";
            }
            if (
              this.$store.state.disp.displays &&
              this.$store.state.disp.displays[this.displayId]
            ) {
              const { wellId } =
                this.$store.state.disp.displays[this.displayId];
              if (this.wellNames.includes(wellId)) well_name = wellId;
            }

            // this.selectedWell =
            //   this.wellNames[this.wellNames.indexOf(well_name)];
            // console.log(
            //   "selected well :::::::",
            //   this.$store.state.disp.displays[this.displayId],
            //   well_name
            // );
            // this.wellBoreNames = Object.keys(
            //   this.$store.state.data.wells[this.selectedWell]["wellbores"]
            // );
            // console.log(this.wellBoreNames,'from store wellborenames')
            // this.selectedWellbore = this.wellBoreNames[0];

            // this.logNames = Object.keys(
            //   this.$store.state.data.wells[this.selectedWell]["wellbores"][
            //   this.selectedWellbore
            //   ]["logs"]
            // );
            // this.selectedLog = this.logNames[0];
          }
        }
        // else {
        //   if (this.wellNamesOrg.length == 0) {
        //     this.setWellSelectionOptions(this.$store.state.data.wells);
        //   }
        // }
        console.log(" this well : ", this.wellNamesOrg, this.wells)
        this.wells = this.$store.state.data.wells;
        // return this.$store.state.data.isCustomerUpdate;
      } catch (err) {
        console.error(err);
      }
    },
    rects() {
      let allRects = this.$store.state.rect.rects;
    },
    imageUrl() {
      // Check if the URL is available in the Vuex store
      console.log(this.$store.state.data.customerLogo, " customer logo")
      if (this.$store.state.data.customerLogo) {
        this.$store.dispatch("data/setCustomerUpdate", true);
        this.wells = this.$store.state.data.wells;
        this.isCustomerUpdate;
        return this.$store.state.data.customerLogo;
      }
      // If not, check for a default logo
      else if (customer_default_logo) {
        return customer_default_logo;
      }

      // If neither is available, fallback to a default image
      else {
        // Assuming 'user.png' is located in the assets folder
        return require('@/assets/img/user.png');
      }
      this.setDisplay()

    },
   
    // filteredAlerts() {
    //   // const validAlerts = this.alertsfound.filter(alert => alert !== '' && alert != null);
    //   const validAlerts = this.alertfound.filter(alert => alert !== '' && alert != null);
    //   console.log('alert_filtered', validAlerts);
    //   console.log('alert_filtered_length__', validAlerts.length);
    //   console.log('dbAlerts__', this.dbPreviousAlerts)
    //   if (validAlerts.length > 0) {
    //     this.previousAlerts = validAlerts;
    //     localStorage.setItem('previousAlerts', JSON.stringify(this.previousAlerts));
    //   }else{
    //     var alertname = this.dbPreviousAlerts.map(alert => alert.alert);
    //     this.previousAlerts = alertname
    //     console.log('alert_filtered_db', validAlerts);
    //     console.log('alert_filtered_dbPreviousAlerts', alertname);
      
    //   return this.currentAlerts
    //   }
    //   return validAlerts;
    // },
    // currentAlerts() {
    //   const filteredAlerts = this.filteredAlerts; // Ensure reactivity
    //   console.log('~~~~~~filteredAlerts~~~~~~',filteredAlerts)
    //   if (filteredAlerts?.length === 0) {

    //     const previousAlertsFromStorage = this.dbPreviousAlerts.map(alert => alert.alert); //localStorage.getItem('currentAlerts');
    //     this.previousAlerts = previousAlertsFromStorage ? JSON.parse(previousAlertsFromStorage) : [];

    //     if (this.previousAlerts.length > 0) {
    //       const matchingAlerts = this.alertBasedMnemonic.filter(alert =>
    //         this.previousAlerts.includes(alert.alertName),
    //         this.selectSingleAlertName(this.previousAlerts)
    //       );

    //       if (matchingAlerts.length > 0) {
    //         console.log('___matchingAlerts__', matchingAlerts);
    //         this.Alert_Mnemonic_data = matchingAlerts.flatMap(alert => {
    //           return alert.mnemonics.map(mnemonic => ({
    //             name: mnemonic.toLowerCase(),
    //             full_name: mnemonic.toLowerCase(),
    //             fullName: mnemonic.toLowerCase()
    //           }));
    //         });

    //         const mnemonicsString = matchingAlerts.map(alert => alert.mnemonics.join(' ')).join(' ').toLowerCase();

    //         const tempMN = this.MnemonicListTemp.filter(mnemonic =>
    //           mnemonicsString.includes(mnemonic.title?.toLowerCase())
    //         );

    //         console.log('__current__alertCategory', this.highAlertCategory);
    //         console.log('matchingAlerts_mn', tempMN);
    //         console.log('___Alert_Mnemonic_data___', this.Alert_Mnemonic_data);
    //       }

    //       this.categorizeAlerts(this.previousAlerts);
    //       this.selectSingleAlertName(this.previousAlerts)
    //     }

    //     return this.previousAlerts;
    //   }
    //   this.categorizeAlerts(filteredAlerts);
    //   console.log('alertt___', this.dbPreviousAlerts )
    //   return filteredAlerts;
    // }
    filteredAlerts() {
  // Correct variable name
  const validAlerts = Array.isArray(this.alertsfound) ? this.alertsfound.filter(alert => alert !== '' && alert != null) : [];
  console.log('alert_filtered', validAlerts);
  console.log('alert_filtered_found', this.alertsfound);
  console.log('alert_filtered_length__', validAlerts.length);
  console.log('dbAlerts__', this.dbPreviousAlerts);
    this.getHighCategory()
    this.getMediumCategory()
    this.getLowCategory()
  if (validAlerts.length > 0) {
    this.previousAlerts = validAlerts;
    this.getPrevAlerts()
    localStorage.setItem('previousAlerts', JSON.stringify(this.previousAlerts));
  } else {
    // Ensure dbPreviousAlerts contains an array of objects with an 'alert' property
    // console.log(this.dbPreviousAlerts,'previous alerts')
   
    if(this.dbPreviousAlerts.length > 0){
      const alertNames = this.dbPreviousAlerts.map(alert => alert.alert);
      this.previousAlerts = alertNames;
      console.log('alert_filtered_db', validAlerts);
      console.log('alert_filtered_dbPreviousAlerts', alertNames);
      try {
        this.getHighCategory()
        this.getMediumCategory()
        this.getLowCategory()
      } catch (error) {
        console.log("error is : ", error)
      }
     
      this.selectSingleAlertName(this.previousAlerts)
    }
    
    // return this.currentAlerts
    // this.categorizeAlerts(this.previousAlerts);
  }

  return validAlerts;
},

currentAlerts() {
  const filteredAlerts = this.filteredAlerts; // Call the function correctly
  console.log('~~~~~~filteredAlerts~~~~~~', filteredAlerts);

  if (filteredAlerts?.length === 0) {
    // Correctly handle previous alerts from storage
    const previousAlertsFromStorage = localStorage.getItem('currentAlerts') //this.dbPreviousAlerts.map(alert => alert.alert);
    this.previousAlerts = previousAlertsFromStorage?.length ? previousAlertsFromStorage : [];

    if (this.previousAlerts.length > 0) {
      const matchingAlerts = this.alertBasedMnemonic.filter(alert =>
        this.previousAlerts.includes(alert.alertName)
      );

      if (matchingAlerts.length > 0) {
        console.log('___matchingAlerts__', matchingAlerts);
        this.Alert_Mnemonic_data = matchingAlerts.flatMap(alert =>
          alert.mnemonics.map(mnemonic => ({
            name: mnemonic.toLowerCase(),
            full_name: mnemonic.toLowerCase(),
            fullName: mnemonic.toLowerCase()
          }))
        );

        const mnemonicsString = matchingAlerts.map(alert => alert.mnemonics.join(' ')).join(' ').toLowerCase();
        const tempMN = this.MnemonicListTemp.filter(mnemonic =>
          mnemonicsString.includes(mnemonic.title?.toLowerCase())
        );

        console.log('__current__alertCategory', this.highAlertCategory);
        console.log('matchingAlerts_mn', tempMN);
        console.log('___Alert_Mnemonic_data___', this.Alert_Mnemonic_data);
      }

      this.categorizeAlerts(this.previousAlerts);
      this.selectSingleAlertName(this.previousAlerts);
    }

    return this.previousAlerts;
  }

  this.categorizeAlerts(filteredAlerts);
  console.log('alertt___', this.dbPreviousAlerts);
  return filteredAlerts;
},
maxDate() {
    return new Date(this.showMax).toLocaleDateString('en-US', { timeZone: this.time_zone });
  }

  },
  watch: {
    rects() {
      let allRects = this.$store.state.rect.rects;
      return allRects.filter(this.noDisplays);
    },
    filteredAlerts(newAlerts) {
      console.log('__check_trend_mn', this.rect.plotConfig)
      if (newAlerts.length > 0) {
        this.playSound()
        this.startCountdown()
        this.getHighCategory()
        this.getMediumCategory()
        this.getLowCategory()
        localStorage.setItem('currentAlerts', JSON.stringify(newAlerts));
        // return this.currentAlerts
        // console.log('__alertsFrom_DB', this.dbPreviousAlerts)
      }
      this.categorizeAlerts(this.previousAlerts);

    }
  },
  created() {
   
  },
  sockets: {
    connect: function () { },
    disconnect() {
      try {
        this.$socket.emit("unsubscribe", {
          logId: `${this.selectedWell}~**~=~**~${this.selectedWellbore}~**~=~**~${this.dLog}`,
          topic: this.topicsName,
          id: this.displayId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },

    reconnect() {
      this.$socket.emit("subscribe", {
        logId: `${this.selectedWell}~**~=~**~${this.selectedWellbore}~**~=~**~${this.dLog}`,
        topic: this.topicsName,
        fields: [...this.all_alert_mnemonics, 'alert'],
        id: this.displayId,
        clientId: this.$clientId,
      });
    },
  },
  async mounted() {
    this.deleteCurrentDisplay();
    this.$store.state.uis.leftSideBarOpen == false
    this.closeMnemonicsModel()
    this.getTags()
    console.log(window.innerHeight,'from innerheight')
    this.selectedTonesrc = this.audioSources[0]
    // this.getAlertsMapped()
    this.getHighCategory()
    this.getMediumCategory()
    this.getLowCategory()
    this.startCountdown()
    this.Mnemonic_data = tempDashMnData.screen['widgets']
    this.MnemonicListTemp = tempDashMnData.screen['widgets']
    console.log('_____tempDash_data____', tempDashMnData)
    // console.log('_____customer_data_selected____', this.$store.state.data.userDetails['entity_id'])
    this.shuffleColors();
    this.assignColors();
    //  this.Mnemonic_data.forEach(item => {
    //         item.randomColor = this.getRandomColor();
    //     });
    this.$store.dispatch("data/setCustomerUpdate", true);
    console.log('_____tempDash_data____Mnemonic_data', this.Mnemonic_data)
    this.wells = this.$store.state.data.wells;
    await this.getAlertBasedMnemonics()
    console.log('_____well__list___', this.$store.state.data.wells)
    // this.setWellSelectionOptions(this.wells);
    
    if(this.wells){
      for (let i in this.wells) {
        this.wellBoreNamesOrg= [...this.wellBoreNamesOrg,...Object.keys(this.wells[i].wellbores)];
      }
    }
    
    let displayDetails = this.$store.state.disp.displays[this.displayId]; // TO SHOW DETAILS IF HAD DATA IN THE STORE
    if (
      displayDetails != null &&
      displayDetails?.wellId != null &&
      displayDetails?.wellboreId != null &&
      displayDetails?.wellboreState != null 
    ) {
      let well_id = displayDetails?.wellId
      this.wells = this.$store.state.data.wells;
      this.selectedWell = well_id;
      this.dWell = this.selectedWell
                  ? this.wells[this.selectedWell].name +
                  (displayDetails?.rigName
                    ? " - " + displayDetails?.rigName
                    : "")
                  : "Well";
      this.dWellbore = displayDetails?.wellboreId
        ? this.wells[well_id]["wellbores"][
          displayDetails?.wellboreId
        ].wellbore_name +
        (displayDetails?.rigName
          ? " - " + displayDetails?.rigName
          : "")
        : "Wellbore"; 
      this.selectedWellbore = displayDetails?.wellboreId;
      this.wellBoreNames = [];
      this.wellBoreNamesOrg = [];
      // for (let i in this.wells[well_id]["wellbores"]) {
      //   this.wellBoreNames.push(
      //     i +
      //     (this.wells[well_id].rigname
      //       ? " - " + this.wells[well_id].rigname
      //       : "")
      //   );
      //   this.wellBoreNamesOrg.push(
      //     this.wells[well_id]["wellbores"][i].wellbore_name +
      //     (this.wells[well_id].rigname
      //       ? " - " + this.wells[well_id].rigname
      //       : "")
      //   );
      // }
      for (let i in this.wells) {
        this.wellBoreNamesOrg=[...this.wellBoreNamesOrg,...Object.keys(this.wells[i].wellbores)];
      }
      this.logNames = [];
      for (let i in this.wells[well_id]["wellbores"][
        displayDetails?.wellboreId
      ]["logs"]) {
        this.logNames.push(i);
      }

    //   this.dLog = displayDetails?.logType
    //     ? displayDetails?.logType
    //     : this.isLwd
    //       ? "LWD"
    //       : "LOGS";    
    }


    this.subNavBarWatcher = this.$store.subscribe(
      (mutation, state) => {
        switch (mutation.type) {
          case "disp/setDisplay":
            if (mutation.payload.display == this.displayId) {
              try {
                let well_id = mutation.payload.wellId;
                this.selectedWell = well_id ? well_id : "Well";
                this.dWell = well_id
                  ? this.wells[well_id].name +
                  (mutation.payload.rigName
                    ? " - " + mutation.payload.rigName
                    : "")
                  : "Well";

                this.selectedWell = well_id;

                this.dWellbore = mutation.payload.wellboreId
                  ? this.wells[well_id]["wellbores"][
                    mutation.payload.wellboreId
                  ].wellbore_name +
                  (mutation.payload.rigName
                    ? " - " + mutation.payload.rigName
                    : "")
                  : "Wellbore";
                this.selectedWellbore = mutation.payload.wellboreId;
                this.wellBoreNames = [];
                this.wellBoreNamesOrg = [];
                // for (let i in this.wells[well_id]["wellbores"]) {
                //   this.wellBoreNames.push(
                //     i +
                //     (this.wells[well_id].rigname
                //       ? " - " + this.wells[well_id].rigname
                //       : "")
                //   );
                //   this.wellBoreNamesOrg.push(
                //     this.wells[well_id]["wellbores"][i].wellbore_name +
                //     (this.wells[well_id].rigname
                //       ? " - " + this.wells[well_id].rigname
                //       : "")
                //   );
                // }
                for (let i in this.wells) {
                  this.wellBoreNamesOrg=[...this.wellBoreNamesOrg,...Object.keys(this.wells[i].wellbores)];
                }
                this.logNames = [];
                for (let i in this.wells[well_id]["wellbores"][
                  mutation.payload.wellboreId
                ]["logs"]) {
                  this.logNames.push(i);
                }

                this.dLog = mutation.payload.logType
                  ? mutation.payload.logType
                  : this.isLwd
                    ? "LWD"
                    : "LOGS";
              } catch (error) { }
            }
            break;

          case "data/setWells":
            this.wells = mutation.payload
            console.log("wells updated: ",)
            break;
          // case "data/setCustomer":
          //   this.deleteCurrentDisplay();
          //   break;            
          default:
            break;
        }
      }
    );
    this.currentAlerts.forEach((_, index) => {
      // this.$set(this.muteStatus, index, false);
    });
    // this.setDisplay()

    window.addEventListener('resize', this.updateScreenSize);
    this.calculateSectionDimensions();
    window.addEventListener('resize', this.calculateSectionDimensions);
    window.addEventListener('resize', this.calculateTD_Dimensions);
    this.updateSectionWidth();
    window.addEventListener('resize', this.updateSectionWidth);

  },
};
</script>
<style scoped>
.widget__overflow {
  overflow-x: overlay;
  overflow-y: hidden;
}

.widget__overflow::-webkit-scrollbar {
  height: 3px !important;
}

.bg_gray {
  background-color: #141414;
}

.bg_gray_600 {
  background-color: #2e2e2e;
}

.nav_bar {
  /* font-family: "Lato", sans-serif; */
  /* "Poppins"; */
  font-size: 14px;
  /* font-weight: 500; */
  color: #ffffff50;
}

.ml-6rem {
  margin-left: 6.6rem;
}

/* .ml-4rem {
    margin-left: 4.4rem;
  } */
.ml-4rem {
  margin-left: 3.7rem;
}

.nav_item {
  padding: 0.8rem 0.2rem;
}

.nav_item.active {
  opacity: 1;
  border-bottom: 2px solid #47a08e;
  color: white;
}

.text_light_gray {
  color: #ffffff80;
}

.bg_gray_400 {
  background-color: #414141;
}

.nav_bar_dashboard {
  /* font-family: "Lato", sans-serif; */
  /* "Poppins"; */
  font-size: 12px;
  /* font-weight: 500; */
  color: #595959;
}

.mr_5 {
  margin-right: 2rem;
}

.nav_item_dashboard.active {
  color: white;
}

.text_light_gray {
  color: #959595;
}

.btn-dashboard-nav {
  background-color: #414141;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 11px;
  padding-top: 1px;
  padding-right: 0.75rem;
  padding-bottom: 1px;
  padding-left: 0.75rem;
  /* font-family: "Lato", sans-serif; */
  /* "Poppins"; */
  /* font-weight: 400; */
}

.dropdown-border-bottom {
  border-bottom: 1px solid #707070;
}

.dropdown_label {
  color: #ffffff70;
  /* font-family: "Lato", sans-serif; */
  /* "Poppins"; */
  /* font-weight: 400; */
  font-size: 14px;
}

/* .tab_animation{
    width: 100px;
    background: red;
    position: relative;
    animation: myfirst 5s 2;
    animation-direction: alternate;
  }
  @keyframes myfirst {
    0%   {background: red; top: 0px;}
    50%  {background: yellow; top: 50px;}
    100%  {background: blue; top: 100px;}
  } */
.expand {
  height: 50px;
  /* animation: slideDown 1ms linear; */
  overflow: hidden;
  transition-timing-function: linear;
}

/* .expand {} */
.expand-leave-active.expand-leave-to {
  transition-timing-function: linear;
  transition: height 0.1s;
  height: 0;
}

.text_xs {
  font-size: 8px;
  font-weight: 100;
}

.text_xs_ {
  font-size: 10px;
}

/* @keyframes slideDown{
      from {
          height: 0;
      }
      to {
          height: 50px;
      }
  } */
.bg_dark_gray {
  padding: 1px 0;
  margin-bottom: 0;
}

.sub_ {
  padding: 0px;
}

.responsive {
  width: 75%;
  height: auto;
}

/* img {
    max-width: 35%;
  } */
#parent {
  position: inherit;
  /* width: 94%; */
  top: 4px;
  margin: 0 auto;
}

#selector {
  padding-top: 0px;
  max-width: 21%;
  /* margin: 0 15px 0 0; */
}

.cust_logo_list {
  max-width: 20% !important;
  width: 100% !important;
  object-fit: contain;
  background-attachment: fixed;
}

.customer_logo {
  width: 100%;
  object-fit: contain;
  background-attachment: fixed;
  height: 7%;
  /* width: 44px; */
  position: fixed;
  right: 0px;
  top: 0;
  z-index: 9999;
  -webkit-background-size: 32px 32px;
  background-size: 21px 24px;
  border: 0;
  /* -webkit-border-radius: 10%; */
  /* border-radius: 10%; */
  display: block;
  margin: 0 10px;
  position: relative;
  height: 26px;
  z-index: 0;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 90%),
    0 4px 6px -2px rgb(0 0 0 / 92%);
}

.cust_drop_list {
  position: absolute;
  top: 33px;
  z-index: 999;
  right: 20px !important;
  background: var(--nav1BgClrsame);
  text-align: center;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
}

.cust_drop_list_head {
  background: var(--sidebarButtonbg);
  font-size: 10px;
  padding: 2px 0;
}

.cust_drop_list_apps {
  font-size: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  color: var(--textColor);
}

.appS_ {
  /* width: 75%; */
  margin: 1px auto;
  text-align: center;
  font-size: 11px;
  display: flex;
  justify-content: flex-start;
  background: var(--root_nav);
  /* border-bottom: 1px solid black; */
  /* color: var(--colorBoxPopUpDeleteText); */
  color: var(--textColor);
  /* font-weight: 600; */
}

.appS_>img {
  width: 17px;
  height: 17px;
  margin: 0 10px;
  padding: 2px;
}

/* .menu_btns{
    margin: 0 40px;
  } */
.screen_:hover {
  background: #5aba81;
}

.screen_:active {
  background: #5aba81;
}

.model_height {
  height: 100%;
}

.bg_model {
  background: #0e1016;
  height: 100%;
}

.bg_cancel {
  background: red;
}

.bg_modal_btns {
  width: 50%;
  font-size: 9px;
  padding: 1px;
}

.bg_create {
  background: #5aba81;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) #479669;
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.model_bg {
  background: #242b34e8;
  z-index: 999999;
}

[type="radio"] {
  /* border: 1px solid #5aba81 !important; */
}

[type="radio"]:checked {
  border: 1px solid #5aba81 !important;
}

select {
  width: 258px;
  position: relative;
  /* left: -17px; */
  margin: 0;
  border: 1px solid #dde1e4;
  border-top: none;
  font-size: 9pt;
}

datalist {
  display: none;
}

option {
  padding: 3px;
}

option:hover {
  background-color: #11e8ea;
}

h3,
h4 {
  text-align: center;
}

/* to hide datalist arrow in webkit */
input::-webkit-calendar-picker-indicator {
  display: none;
}

.modal_width {
  width: 22%;
}

.rounded-t {
  padding-bottom: 10px;
  font-size: 10px;
}

.modal_body {
  font-size: 10px;
  padding: 0 15px 18px 15px;
}

._table_modal {
  overflow-y: scroll;
  padding-bottom: 0;
}

.text-base {
  line-height: 0;
  color: #e6f22b;
}

.table-fixed {
  /* font-family: Open Sans,sans-serif; */
}

.t_head {
  background: #dee2e62b;
  color: #5aba81;
}

.tbl_mdl {
  margin-top: 10px;
  margin-bottom: 23px;
  height: 97vh;
}

.label_ {
  font-size: 8px;
}

/* css for search and select */
.dropdown-content_select {
  background-color: hsl(0, 0%, 96%);
  overflow: hidden;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-list_select {
  background-color: #f6f6f6;
  position: absolute;
  width: 100%;
  /* max-height: 200px; */
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-content_select_a {
  color: black;
  padding: 5px;
  text-decoration: none;
  display: block;
  font-size: 9px;
}

.nav_hide_icon {
  display: flex;
  justify-content: end;
  color: white;
  margin-top: -9px;
  width: 20px;
  /* margin-right: 10px; */
  float: right;
  position: absolute;
  right: 0;
}

.sec_nav_1 {
  width: 2%;
}

.sec_nav_2 {
  /* width: 98%; */
  width: 100%;
}

.second_tab {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

._phone {
  background: #1b1a1f;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  align-self: center;
  border-radius: 50%;
  font-size: 20px;
  width: 30px;
  transform: rotate(-41deg);
}

.hamburger_icon {
  display: flex;
  align-items: center;
  /* position: absolute;
    left: 14px; */
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 10px;
  /* top: 15px; */
  -webkit-transform: scale(2);
  transform: scale(2);
  transition: 0.4s ease all;
  -webkit-transition: 0.4s ease all;
}

.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 30px;
  background-color: #e9e9ea;
  cursor: pointer;
  transition: 0.4s;
  -webkit-transition: 0.4s;
}

.switch-btn::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 0px;
  bottom: 0px;
  border-radius: inherit;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  /* transition: 0.4s  ease all;
    -webkit-transition: 0.4s  ease all; */
  /* transition: 0.4s ease all, left 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15); */
}

.switch-btn:active:before {
  /* width: 40px; */
}

.checkbox:checked+.switch-btn {
  background-color: #414141;
}

.checkbox:checked+.switch-btn::before {
  /* left: calc(100% - 10px);
    transform: translateX(-100%); */
  left: calc(100% - 0px);
  /* -webkit-transform: translateX(-100%);*/
  transform: translateX(-100%);
}

.fa-chevron-circle-up {
  background: black;
  border-radius: 100%;
}

.fa-chevron-circle-down {
  background: black;
  border-radius: 100%;
}

/* accordian styles */
.acc_label:after {
  content: "+";
  position: absolute;
  right: 1em;
  /* color: var(--colorBoxPopUpDeleteText); */
  color: var(--textColor);
  font-size: 15px;
}

input:checked+.acc_label:after {
  content: "-";
  /* line-height: 0.8em; */
  font-size: 20px;
}

.accordion__content {
  max-height: 0em;
  /* transition: all 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87); */
}

input[name="panel"]:checked~.accordion__content {
  /* Get this as close to what height you expect */
  max-height: 50em;
}

.custo_accordian_ {
  max-height: 65vh;
  overflow-y: auto;
}

.logout_accordian {
  display: flex;
  justify-content: center;
  font-size: 11px;
  background: var(--root_nav);
  margin-top: 2px;
  color: var(--textColor);
  /* font-weight: 600; */
}

/* .cust_drop_list::before {
    content: "\1F781";
    font-size: 14px;
    position: absolute;
    right: 2px;
    top: 0px;
    color: var(--sidebarbg);
  } */
/* theme toggle */
.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-color: #414141;
  width: 34px;
  height: 16px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox:checked+.ball {
  background-color: red;
}

.theme_icon {
  font-size: 9.5px !important;
}

.fa-moon {
  color: #f1c40f;
}

.fa-sun {
  color: #f39c12;
}

.checkbox-label .ball {
  background-color: #fff;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 4px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked+.checkbox-label .ball {
  transform: translateX(14px);
}

:root {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 3px 7px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.popper {
  font-size: 8px;
}

/* styles for header txt start*/
.navDiv {
  background: var(--nav1BgClrsame);
  height: 48px;
  padding: 0 10px;
  transition: var(--transition);
}

.__close_box {
  background: var(--navBar2Bg);
  font-weight: 800 !important;
  color: var(--activeTextColor) !important;
}

.apollodart_heading {
  margin: 0px;
  /* font-family: "Poppins"; */
  font-weight: 600;
  font-size: 21px;
  text-transform: uppercase;
  color: var(--nav1HdngClrsame);
}

.apollodart_heading>span {
  /* font-family: "Poppins"; */
}

.navMiddleSection {
  padding-left: 40px;
  height: 100%;
  margin: 0 auto;
}

.navMiddleList {
  gap: 20px;
  height: 100%;
}

.navMiddleList li {
  margin-right: 15px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  height: 100%;
}

.navMiddleList li .textColor {
  font-size: min(1.1vw, 16px);
  /* calc(.6vw + .4vh + .55vmin); */
  font-weight: 100;
  letter-spacing: 0.15em;
  color: var(--searchColor);
  /* var(--colorBoxWhiteNum); */
}

.navMiddleList>li>.textColor:hover {
  color: var(--textColor);
  transition: var(--transition);
}

.navMiddleList li .activeTextColor {
  font-size: min(1.1vw, 16px);
  /* calc(0.6vw + 0.3vh + 0.55vmin); */
  letter-spacing: 0.05em;
  color: var(--activeTextColor);
  border-bottom: 2px solid;
  padding-top: 0rem;
  font-weight: 600;
  letter-spacing: 0.15em;
}

.navRightSubDiv {
  gap: 25px;
}

.navBar2 {
  background: var(--navBar2Bgsame);
  height: 32px;
  transition: var(--transition);
  opacity: 1;
}

.navBar2 #header>div {
  height: 32px;
  display: flex;
  align-items: center;
}

.navBar2saveBtn {
  margin-bottom: 3.5px;
}

.activeClass {
  color: var(--activeTextColor) !important;
  border-bottom: 2px solid;
}

.bgClr {
  background: var(--activeTextColor);
}

.tgglAsideBar {
  padding: 1px 10px;
  margin-left: 10px;
  color: var(--colorBoxWhiteNum);
}

/* styles for header txt end*/
/* New styles for sub menu by Prerana Kasar */
.sideBarBackground {
  /* background-color: var(--root_nav); */
  width: 40px;
  height: 32px;
}

.menuItem {
  margin-left: 10px;
  /* font-family: "Open Sans"; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: min(1vw, 16px);
  /* <!-- calc(0.60vw + 0.3vh + 0.45vmin); --> */
  letter-spacing: 0.25em;
  text-transform: uppercase;
  background: transparent;
  border: none;
  color: var(--searchColor);
  /* #dad7d7; */
  /* var(--colorBoxWhiteNum); */
}

.menuItem:hover {
  color: var(--textColor);
  transition: var(--transition);
}

.sec_nav_2 .menuItem {
  /* font-size: calc(0.3vw + 0.3vh + 0.35vmin); */
}

.menuItem>div {
  margin: 0 !important;
  padding: 1px 10px !important;
  border: 0 !important;
}

.activenav {
  color: var(--activeTextColor) !important;
  border-bottom: 2px solid;
  cursor: pointer;
}

.activesvg svg path {
  fill: var(--textColor);
}

.activesvgLight path {
  fill: #073099 !important;
}

.customerLabel {
  margin-top: 0;
}

.notificationPopUp {
  width: 75%;
  z-index: 9999;
  position: absolute;
  top: 100%;
  right: 80%;
  visibility: hidden;
}

.settingsPopUp {
  width: 75%;
  z-index: 9999;
  position: absolute;
  top: 100%;
  right: 80%;
  visibility: hidden;
}

.label_alerts {
  padding: 8px;
  margin-top: 10px;
}

.alertScreen {
  background: var(--sidebarbg);
  /* box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15); */
}

.selectAlertType {
  color: var(--textColor);
  font-size: 13px;
  font-weight: 600;
}

/* recently added popup related to alerts */
.rightSideBarNew {
  background: var(--sidebarbg);
  height: calc(100vh - 85px);
  width: 15%;
  right: 5px;
  top: 85px;
  position: fixed;
  overflow-y: auto;
}

.settingsHeader {
  color: #b4b5ba;
  font-size: 14px;
  font-weight: 600;
}

.selectEleAlerts {
  width: fit-content;
}

.option {
  width: fit-content;
}

.alertTypeLabel {
  color: var(--textColor);
}

.widgetContainer {
  background: var(--colorBoxBg);
  /* width: 120px; */
  height: 29px;
  padding: 15px;
  margin: 6px;
}

.widgetName {
  color: var(--textColor);
  font-size: 13px;
}

.headerLabel {
  background: var(--sidebarListBgColor);
  height: 30px;
  color: var(--textColor);
  font-size: 13px;
  cursor: pointer;
  user-select: none;
}

.selectele {
  background-color: var(--rightBarSelecteleBg);
  height: 30px;
  color: var(--textColor);
  font-size: 10px;
  outline: none;
  border: none;
  line-height: normal;
  padding-left: 5px;
}

.selectLabel {
  color: var(--colorBoxHeadingText);
  font-size: 11px;
}

.inScreenAlertHeading {
  color: #b4b5ba;
}

/* .customRadio {
  } */
.downArrowIcon {
  padding-top: 1px;
}

.popperOfAlert {
  width: 112%;
}

/* styles for sendmail code starts */
.captr_N_SendMail {
  color: var(--textColor);
}

.sendMail_modal,
.confirm_box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgb(28 29 31 / 70%);
  display: flex;
  z-index: 99999 !important;
  align-items: center;
}

.Child_Of_sendMail_modal {
  /* width: 90%; */
  /* height: 85%; */
  margin: auto;
  border-radius: 2px;
  /* background: var(--colorBoxPopUpBg); */
  background: var(--sidebarbg);
  color: var(--textColor);
}

.appHeader .Child_Of_sendMail_modal {
  background: var(--sidebarbg);
}

.applyFont {
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
}

.fontWeight-600 {
  font-weight: 600;
}

.emailSubmit_Form input {
  border: none;
  border-radius: 3px;
  left: 0;
  height: 30px;
  /* font-size: 13px; */
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
  padding: 0px 20px 2px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  appearance: auto;
  /* background: var(--searchBarBg); */
  /* color: var(--searchColor); */
  background: var(--sidebarListBgColor);
  color: var(--textColor);
  font-weight: 600;
}

.emailSubmit_Form {
  border: none;
  border-radius: 3px;
  left: 0;
  height: 30px;
  /* font-size: 13px; */
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
  padding: 0px 20px 2px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  appearance: auto;
  /* background: var(--searchBarBg); */
  /* color: var(--searchColor); */
  background: var(--sidebarListBgColor);
  color: var(--textColor);
  font-weight: 600;
}

.emailSubmit_Form textarea {
  border: none;
  border-radius: 3px;
  /* font-size: 13px; */
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
  background: var(--searchBarBg);
  /* color: var(--searchColor); */
  background: var(--sidebarListBgColor);
  color: var(--textColor);
  font-weight: 600;
}

.show_Cc_Btn {
  color: var(--activeTextColor);
  border: 1px solid currentColor;
}

.confirm_Box_Icon {
  color: var(--activeTextColor);
}

.Confirm_Dialogue_no {
  color: var(--activeTextColor);
  border: 1px solid currentColor;
}

.confirm_Dia_Box {
  /* background: var(--colorBoxPopUpBg); */
  color: var(--textColor);
  background: var(--sidebarbg);
}

.c-toast-container {
  z-index: 99999999 !important;
}

.sendMailbtn[disabled] {
  opacity: 0.6;
  pointer-events: none;
}

.bg-none {
  filter: opacity(0.6);
}

.addBg {
  /* background: var(--searchBarBg);
    color: var(--searchColor); */
  background: var(--sidebarListBgColor);
  color: var(--textColor);
}

.attachmentInfoDiv::before {
  content: "";
  border-bottom: 10px solid var(--searchBarBg);
  border-left: 10px solid transparent;
  position: absolute;
  border-right: 10px solid transparent;
  top: -9px;
  left: 1px;
}

.attachmentInfoDiv {
  top: 35px;
  border-radius: 3px;
  background: var(--searchBarBg);
  margin-left: 1px;
}

.colorBarsOfTheme {
  width: 200px;
  height: 20px;
}

.themModalContainer {
  width: 40px;
  height: fit-content;
  background: var(--root_nav);
  right: 10px;
  top: 20px;
  transition: opacity 0.3s ease-in-out;
  z-index: 9999;
  padding: 1px;
  border-radius: 5px;
}

.themeHeading {
  font-size: 15px;
  color: var(--searchColor);
}

.circles div {
  width: 100%;
  height: 22px;
  border-radius: 13px;
  /* border-radius: 50%; */
  cursor: pointer;
  border: 1px solid var(--searchColor);
  margin: 5px 0px;
}

.circles img {
  width: 25px;
  height: 25px;
}

.circle1 {
  background-color: #2a2a2a;
}

.circle2 {
  background-color: #fff;
}

.circle3 {
  background-color: #0f172a;
}

.bellIcon_light path {
  fill: #000;
}

.themeActive {
  border: 1px solid var(--activeTextColor) !important;
}

.active_customer {
  background: var(--activeCustomerBg);
}

.right_arrow_div,
.left_arrow_div {
  background-color: white;
  width: 26px;
  height: 26px !important;
  position: fixed;
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  right: 30px;
  visibility: hidden;
}

.left_arrow_div {
  /* transform: rotate(180deg); */
  left: 30px;
}

.widget__overflow:hover .right_arrow_div,
.widget__overflow:hover .left_arrow_div {
  visibility: visible;
}

.widget__overflow:not(:hover) .right_arrow_div,
.widget__overflow:not(:hover) .left_arrow_div {
  visibility: hidden !important;
}

.right_arrow_div svg {
  transform: rotate(90deg);
}

.left_arrow_div svg {
  transform: rotate(-90deg);
}

.danger {
  color: var(--danger_custom);
}

.danger_border {
  border-left: 5px solid var(--danger_custom);
}

.danger_background {
  background: var(--danger_custom)
}

.moderate {
  color: var(--warning_custom);
}

.moderate_border {
  border-left: 5px solid var(--warning_custom);
}

.moderate_background {
  background: var(--warning_custom)
}

.low {
  color: var(--lowwarn_custom);
}

.low_border {
  border-left: 5px solid var(--lowwarn_custom);
}

.low_background {
  background: var(--lowwarn_custom)
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1px;
  padding: 0 10px 0 4px;
}

.grid-container>div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 8px 0;
  font-size: 30px;
}

.item1 {
  grid-column: 1 / 3;
  height: auto;
}

.item2 {
  height: auto;
}

.item2:last-child {
  /* grid-column: 5 / -1;*/
  /* height: 200px; */
}

.image-wrapper {
  width: 33px;
  height: 33px;
  /* margin: 0 auto; */
}

.image {
  width: 100%;
  height: 100%;
}

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  /* height: 47px; */
  /* line-height: 0 !important; */
  /* font-size: unset !important; */
  font-weight: 600;
}

.custom-select .items {
  position: absolute !important;
}

.custom_dash_select .selected {
  font-size: 11.5px !important;
  padding-left: 0 !important;
  height: 15px !important;
}
.selected{
  display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.alertHistPop {
  height: 50%;
  position: absolute;
  right: 1%;
  bottom: 33%;
  overflow-y: auto;
  border-radius: 4px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: var(--navBar2Bgsame);
}

td,
th {
  border: 1px solid var(--navBar2Bgsame);
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: var(--navBar2Bgsame);
}


.datepicker-toggle {
  display: flex;
  position: relative;
  margin: auto;
  height: 24px;
}

.datepicker-toggle-button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/img/apollo_logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.datepicker-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
}

.datepicker-input::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

@keyframes blink {
  0% {
    background-color: var(--navBar2Bgsame);

  }

  50% {
    background-color: red;

  }

  100% {
    background-color: var(--navBar2Bgsame);

  }
}

.danger_blink {
  animation: blink 2s infinite ease-in-out;
  /* animation: blink 1s infinite; */
  /* transition: background-color 0.5s ease; */
}

.danger_without_blink {
  background-color: red;
}

.audio-player {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
}

.play-button {
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.play-button:hover {
  background-color: #2980b9;
}

.collapsible {
  background-color: var(--widgetBg);
  /* color: #444; */
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
  font-size: 0.7rem;
  border-bottom: 1px solid lightgray
}

.active,
.collapsible:hover {
  background-color: var(--widgetBg);
  border-bottom: 1px solid lightgray
}

.content {
  min-height: unset;
  min-width: unset;
  background-color: var(--widgetBg);
  position: unset;
  border-top: 1px solid lightgray
}

.drop_items {
  margin: 10px 5px;
  border-bottom: 1px solid var(--nav1BgClrsame);
  cursor: pointer;
  padding: 6px 0;
}

.drop_items:nth-last-child() {
  border-bottom: none
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 19px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.button_danger {
  border: 2px solid var(--danger_custom);
  border-radius: 25px;
  padding: 5px 10px;
  margin: 5px;
}

.button_medium {
  border: 2px solid var(--warning_custom);
  border-radius: 25px;
  padding: 5px 10px;
  margin: 5px;
}

.button_low {
  border: 2px solid var(--lowwarn_custom);
  border-radius: 25px;
  padding: 5px 10px;
  margin: 5px;
}

.button_Active {
  border: 0.5px solid lightgray;
  border-radius: 25px;
  padding: 5px 10px;
  margin: 5px;
}

.button_inActive {
  border: 0.5px solid lightgray;
  border-radius: 25px;
  padding: 5px 10px;
  margin: 2px;
  background: none
}

.danger_text {
  color: var(--danger_custom);
  ;
}

.warning_text {
  color: var(--warning_custom);
  ;
}

.lowWarning_text {
  color: var(--lowwarn_custom);
  ;
}

.toggle_section {
  /* width: 30%; */
  border-radius: 10px;
  margin: 2px 2px;
  color: white;
  background: var(--widgetBg);
  height: 30px;
}

.toggle_btns_active {
  /* background: var(--nav1BgClrsame); */
  /* padding: 5px; */
  /* border-radius: 25px; */
  margin: 5px 10px 0 10px;
  /* width: 50%; */
  color: var(--activeTextColor);
  border-bottom: 2px solid;
  padding-bottom: 4px;
  font-weight: 600;
}

.toggle_btns_Inactive {
  background: none;
  /* padding: 5px; */
  border-radius: 25px;
  margin: 5px 10px;
  /* width: 50%; */
}

.drillerSection {
  background: var(--widgetBg);
  height: 100%;
  margin: 1px;
  border: 1px solid #a5b7c970;
}

.noUi-target_dup_TD {
  /* width:80%; */
}

.right-section {
  margin-left: 35.3%;
  overflow-y: auto;
  height: 100vh;
  position: relative;
  top: 3.5rem;
}
.mnBg{
    background: var(--nav1BgClrsame) !important;
  /* border: 0.5px solid lightgray; */
    border-radius: 25px;
    padding: 5px 10px;
    margin: 2px;
    background: none;
}
.matchedTag{
  border: 1px solid var(--activeTextColor);
  cursor: not-allowed;
}
.colorBox_text_gray{
  color: var(--colorBoxHeadingText);
}
</style>